.chatbots-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-width: 991px;
  min-height: 100vh;
  flex-direction: column;
  background-color: #f7f7f7;
}
.chatbots-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.chatbots-mainbody {
  flex: 0 0 auto;
  width: calc(100% - 150px);
  height: 100vh;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.chatbots-navbar {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.chatbots-container02 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}
.chatbots-container03 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbots-container04 {
  flex: 0 0 auto;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.chatbots-button {
  color: white;
  cursor: pointer;
  height: 75%;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-300);
}
.chatbots-body {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  margin-top: 30px;
  align-items: flex-start;
  padding-left: 30px;
  margin-bottom: 20px;
  padding-right: 30px;
  flex-direction: column;
}
.chatbots-chatbotcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.chatbots-chatbotheader {
  top: 0;
  flex: 0 0 auto;
  width: 100%;
  height: 55px;
  display: flex;
  position: sticky;
  align-items: flex-start;
  background-color: var(--dl-color-gray-white);
}
.chatbots-container05 {
  flex: 0 0 auto;
  width: 8%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-text04 {
  font-style: normal;
  font-weight: 500;
}
.chatbots-container06 {
  flex: 0 0 auto;
  width: 37%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbots-text05 {
  font-style: normal;
  font-weight: 500;
}
.chatbots-container07 {
  flex: 0 0 auto;
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-text06 {
  font-style: normal;
  font-weight: 500;
}
.chatbots-container08 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-text07 {
  font-style: normal;
  font-weight: 500;
}
.chatbots-container09 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-text08 {
  font-style: normal;
  font-weight: 500;
}
.chatbots-container10 {
  flex: 0 0 auto;
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-text09 {
  font-style: normal;
  font-weight: 500;
}
.chatbots-chatbotitem {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 70px;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-bottom: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.chatbots-container11 {
  flex: 0 0 auto;
  width: 8%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.chatbots-container12 {
  flex: 0 0 auto;
  width: 37%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbots-text10 {
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.chatbots-container13 {
  flex: 0 0 auto;
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-text11 {
  font-style: normal;
  font-weight: 400;
}
.chatbots-container14 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-text12 {
  font-style: normal;
  font-weight: 400;
}
.chatbots-container15 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-text13 {
  font-style: normal;
  font-weight: 400;
}
.chatbots-container16 {
  flex: 0 0 auto;
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-icon {
  fill: var(--dl-color-success-500);
  width: 24px;
  height: 24px;
}
.chatbots-icon2 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.chatbots-formnewbot {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.chatbots-newbotwidget {
  flex: 0 0 auto;
  width: 450px;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: white;
}
.chatbots-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  border-color: #c5c5c5;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbots-container18 {
  flex: 0 0 auto;
  width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-text14 {
  margin-left: 50px;
}
.chatbots-container19 {
  flex: 0 0 auto;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbots-icon4 {
  width: 60%;
  cursor: pointer;
  height: 60%;
}
.chatbots-container20 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 36px;
  flex-direction: column;
}
.chatbots-textinput {
  width: 100%;
  height: 35px;
  border-color: #c5c5c5;
  outline-color: var(--dl-color-primary-300);
}
.chatbots-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: flex-start;
  justify-content: space-between;
}
.chatbots-thq-dropdown {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.chatbots-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbots-text15 {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: 4px;
  vertical-align: middle;
}
.chatbots-dropdown-arrow {
  transition: 0.3s;
}
.chatbots-icon6 {
  width: 18px;
  height: 18px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}
.chatbots-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.chatbots-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbots-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbots-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.chatbots-text16 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.chatbots-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbots-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbots-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.chatbots-text17 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.chatbots-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbots-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbots-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.chatbots-text18 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.chatbots-container22 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 38px;
  justify-content: flex-end;
}
.chatbots-button1 {
  cursor: pointer;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.chatbots-button2 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  height: 30px;
  margin-left: 10px;
  padding-top: 5px;
  border-width: 0px;
  padding-bottom: 5px;
  background-color: var(--dl-color-primary-300);
}
