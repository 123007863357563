.footer-footer {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.footer-logo {
  color: #711755;
  font-size: 24px;
}
.footer-text {
  color: #51575e;
  font-size: 14px;
  margin-top: 25px;
  text-align: center;
}
.footer-text06 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  margin-top: 30px;
  font-weight: 600;
}
.footer-text08 {
  color: #705cf6;
}
.footer-text09 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  margin-top: 30px;
  font-weight: 600;
}
.footer-text10 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 24px;
}

