
  .api-apieditor {
    flex: 0 0 auto;
    width: 100%;
    height: 600px;
    display: flex;
    padding: 20px;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: white;
  }
  .api-title {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 10px;
    border-color: grey;
    border-width: 1px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    justify-content: space-between;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .api-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .api-button {
    color: white;
    cursor: pointer;
    border-width: 0px;
    background-color: var(--dl-color-primary-300);
  }
  .api-methodendpoint {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 30px;
  }
  .api-method {
    flex: 0 0 auto;
    width: 100px;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .api-text01 {
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .api-textinput {
    width: 150px;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .api-endpoint {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .api-text02 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .api-textinput1 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
    height: 30px;
  }
  .api-navbar {
    flex: 0 0 auto;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-color: #DADBDD;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .api-navgroup {
    flex: 1;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .api-text03 {
    color: rgb(131, 135, 140);
    cursor: pointer;
    font-size: 14px;
    margin-right: 15px;
  }
  .api-text04 {
    color: rgb(131, 135, 140);
    cursor: pointer;
    font-size: 14px;
    margin-right: 15px;
  }
  .api-text05 {
    color: #705CF6;
    cursor: pointer;
    font-size: 14px;
    margin-right: 15px;
  }
  .api-text06 {
    color: rgb(131, 135, 140);
    cursor: pointer;
    font-size: 14px;
  }
  .api-text07 {
    color: var(--dl-color-primary-300);
    cursor: pointer;
  }
  .api-headerscontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .api-headerlabel {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .api-method1 {
    flex: 0 0 auto;
    width: 50%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .api-text08 {
    color: #1F2730;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .api-endpoint1 {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .api-text09 {
    color: #1F2730;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .api-header {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .api-method2 {
    flex: 0 0 auto;
    width: 50%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .api-textinput2 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .api-endpoint2 {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .api-textinput3 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
    height: 100%;
  }
  .api-icon {
    fill: #DADBDD;
    width: 20px;
    cursor: pointer;
    height: 20px;
    transition: 0.3s;
    margin-left: 10px;
  }
  .api-icon:hover {
    fill: var(--dl-color-danger-700);
  }
  .api-header1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .api-method3 {
    flex: 0 0 auto;
    width: 50%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .api-textinput4 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .api-endpoint3 {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .api-textinput5 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .api-icon3 {
    fill: #DADBDD;
    width: 20px;
    cursor: pointer;
    height: 20px;
    transition: 0.3s;
    margin-left: 10px;
  }
  .api-icon3:hover {
    fill: var(--dl-color-danger-700);
  }
  .api-button1 {
    color: var(--dl-color-primary-300);
    cursor: pointer;
    border-color: #DADBDD;
  }
  .api-bodycontainer {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
    flex-direction: column;
  }
  .api-bodyheader {
    flex: 0 0 auto;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: flex-start;
  }
  .api-text10 {
    color: rgb(131, 135, 140);
    font-size: 14px;
  }
  .api-text13 {
    color: var(--dl-color-primary-300);
    cursor: pointer;
    font-size: 14px;
    margin-left: 20px;
  }
  .api-jsoncontent {
    flex: 1;
    width: 100%;
    border: 1px solid rgba(120, 120, 120, 0.4);
    height: auto;
    display: flex;
    align-items: flex-start;
  }
  .api-responsecontainer {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
    flex-direction: column;
  }
  .api-bodyheader1 {
    flex: 0 0 auto;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: flex-start;
  }
  .api-text16 {
    color: rgb(131, 135, 140);
    font-size: 14px;
  }
  .api-text18 {
    color: var(--dl-color-success-300);
    font-style: normal;
    font-weight: 600;
  }
  .api-jsonbodycontainer {
    flex: 1;
    width: 100%;
    border: 1px solid rgba(120, 120, 120, 0.4);
    height: 500;
    display: flex;
    align-items: flex-start;
  }
  .api-saveresponse {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .api-mappinglabel {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .api-left {
    flex: 0 0 auto;
    width: 50%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .api-text20 {
    color: rgb(31, 39, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .api-right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
  }
  .api-text21 {
    color: rgb(31, 39, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .api-text22 {
    color: var(--dl-color-primary-300);
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .api-mapping {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .api-jsonpath {
    flex: 0 0 auto;
    width: 50%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .api-textinput6 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .api-variablename {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .api-textinput7 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .api-icon6 {
    fill: #DADBDD;
    width: 20px;
    cursor: pointer;
    height: 20px;
    transition: 0.3s;
    margin-left: 10px;
  }
  .api-icon6:hover {
    fill: var(--dl-color-danger-700);
  }
  .api-button2 {
    color: var(--dl-color-primary-300);
    cursor: pointer;
    border-color: #DADBDD;
  }
  