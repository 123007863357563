.message-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .message-template {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .message-header {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    margin-bottom: 20px;
    padding-right: 10px;
    flex-direction: column;
    background-color: #F7F6F6;
  }
  .message-text {
    margin-bottom: 8px;
  }
  .message-headertype {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .message-text-title {
    width: 100%;
    margin-left: 10px;
  }
  .message-subheader {
    flex: 0 0 auto;
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 100px;
    display: none;
    align-items: flex-start;
    padding-top: 10px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: #F7F6F6;
  }
  .message-variablescontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: #F7F6F6;
  }
  .message-text03 {
    margin-bottom: 5px;
  }
  .message-variable {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .message-text06 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
  }
  .message-text-title1 {
    width: 100%;
    margin-left: 10px;
  }
  .message-variable1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .message-text07 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
  }
  .message-text-title2 {
    width: 100%;
    margin-left: 10px;
  }
  .message-body {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
    padding-right: 10px;
    flex-direction: column;
    background-color: #F7F6F6;
  }
  .message-text08 {
    margin-bottom: 8px;
  }
  .message-sub-body {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .message-text-title3 {
    width: 100%;
    height: 200px;
  }
  .message-variablescontainer1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: #F7F6F6;
  }
  .message-text09 {
    margin-bottom: 5px;
  }
  .message-variable2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .message-text12 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
  }
  .message-text-title4 {
    width: 100%;
    margin-left: 10px;
  }
  .message-variable3 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .message-text13 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
  }
  .message-text-title5 {
    width: 100%;
    margin-left: 10px;
  }
  .message-footer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
    padding-right: 10px;
    flex-direction: column;
    background-color: #F7F6F6;
  }
  .message-text14 {
    margin-bottom: 8px;
  }
  .message-sub-body1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .message-text-title6 {
    width: 100%;
  }
  .message-buttonscontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 0px;
    padding-right: 10px;
    flex-direction: column;
  }
  .message-text15 {
    margin-bottom: 8px;
  }
  .message-quickreply {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    border-color: #F7F6F6;
    border-style: solid;
    border-width: 2px;
    padding-left: 5px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 20px;
    padding-right: 5px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: white;
  }
  .message-text16 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .message-column {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    border-width: 1px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: #F9FAFA;
  }
  .message-text17 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 7px;
  }
  .message-textinput {
    width: 100%;
  }
  .message-visitwebsite {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    border-color: #F7F6F6;
    border-style: solid;
    border-width: 2px;
    padding-left: 5px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 20px;
    padding-right: 5px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: white;
  }
  .message-text18 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .message-row {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-start;
  }
  .message-column1 {
    flex: 1 0 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    border-width: 1px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: #F9FAFA;
  }
  .message-text21 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 7px;
  }
  .message-textinput1 {
    width: 100%;
  }
  .message-column2 {
    flex: 1 0 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    padding-top: 10px;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    border-width: 1px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: #F9FAFA;
  }
  .message-text22 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 7px;
  }
  .message-textinput2 {
    width: 100%;
  }
  .message-variablescontainer2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    flex-direction: column;
    padding-bottom: 10px;
  }
  .message-text23 {
    margin-bottom: 5px;
  }
  .message-variable4 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .message-text26 {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
  }
  .message-text-title7 {
    width: 100%;
    margin-left: 10px;
  }
  .message-visitwebsite1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    border-color: #F7F6F6;
    border-style: solid;
    border-width: 2px;
    padding-left: 5px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 20px;
    padding-right: 5px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: white;
  }
  .message-text27 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .message-row1 {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-start;
  }
  .message-column3 {
    flex: 1 0 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    border-width: 1px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: #F9FAFA;
  }
  .message-text30 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 7px;
  }
  .message-textinput3 {
    width: 100%;
  }
  .message-column4 {
    flex: 1 0 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    padding-top: 10px;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    border-width: 1px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: #F9FAFA;
  }
  .message-text31 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 7px;
  }
  .message-textinput4 {
    width: 100%;
  }
  