.left-menu-container {
    min-width: 100px;
    background-color: #eee;
    padding: 16px 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.left-menu-container .node-btn {
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.left-menu-container .node-btn img {
    width: 40px;
    height: 40px;
}

.left-menu-container .node-btn p {
    margin: 0 !important;
    font-size: .8rem;
    color: gray;
}


/* LeftMenu.css */
.node-btn {
    position: relative;
    width: 60px;
    height: 60px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .node-btn img {
    width: 32px;
    height: 32px;
  }
  
  .node-btn p {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
  }
  
  .node-btn:hover {
    background-color: #f0f0f0;
  }
  
  .node-btn:hover:before {
    content: "Drag to Drop";
    position: absolute;
    top: -5px;
    width: 120px;
    height: 16px;
    background-color: #f0f0f0;
    border-radius: 8px;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
    color: #666;
  }
  