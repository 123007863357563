.subcomponent-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .subcomponent-payment {
    flex: 0 0 auto;
    width: 600px;
    height: 500px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .subcomponent-text {
    color: rgb(31, 39, 48);
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .subcomponent-paymentitem {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    transition: 0.3s;
    align-items: flex-start;
    padding-top: 15px;
    border-color: #DADBDD;
    border-style: solid;
    border-width: 1px;
    padding-left: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
  }
  .subcomponent-paymentitem:hover {
    border-color: #705CF6;
  }
  .subcomponent-radiocontainer {
    flex: 0 0 auto;
    width: 20px;
    cursor: pointer;
    height: 20px;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
  }
  .subcomponent-radiobutton {
    width: 100%;
    height: 100%;
  }
  .subcomponent-cardcontainer {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .subcomponent-text1 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .plan-text1 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
  }


  .subcomponent-container1 {
    width: 300px;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    border-color: #DADBDD;
    border-width: 1px;
    padding-left: 15px;
    border-radius: 5px;
    padding-right: 15px;
    flex-direction: column;
    padding-bottom: 10px;
  }
  .subcomponent-text2 {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .subcomponent-text3 {
    color: rgb(131, 135, 140);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  .subcomponent-paymentitem1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    transition: 0.3s;
    align-items: flex-start;
    padding-top: 15px;
    border-color: #DADBDD;
    border-style: solid;
    border-width: 1px;
    padding-left: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
  }
  .subcomponent-paymentitem1:hover {
    border-color: #705CF6;
  }
  .subcomponent-radiocontainer1 {
    flex: 0 0 auto;
    width: 20px;
    cursor: pointer;
    height: 20px;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
  }
  .subcomponent-radiobutton1 {
    width: 100%;
    height: 100%;
  }
  .subcomponent-cardcontainer1 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .subcomponent-text4 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .subcomponent-cardsicon {
    width: 300px;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    border-color: #DADBDD;
    border-width: 1px;
    padding-left: 15px;
    border-radius: 5px;
    padding-right: 15px;
    flex-direction: row;
    padding-bottom: 10px;
    justify-content: flex-start;
  }
  .subcomponent-cardbox {
    flex: 0 0 auto;
    width: 56px;
    height: 30px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    border-color: #DADBDD;
    border-width: 1px;
    margin-right: 10px;
    padding-left: 10px;
    border-radius: 2px;
    padding-right: 10px;
    padding-bottom: 5px;
    justify-content: center;
  }
  .subcomponent-pasted-image {
    width: 52px;
    height: 30px;
  }
  .subcomponent-cardbox1 {
    flex: 0 0 auto;
    width: 56px;
    height: 30px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    border-color: #DADBDD;
    border-width: 1px;
    padding-left: 10px;
    border-radius: 2px;
    padding-right: 10px;
    padding-bottom: 5px;
    justify-content: center;
  }
  .subcomponent-pasted-image1 {
    height: 24px;
  }
  .subcomponent-paymentitem2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    transition: 0.3s;
    align-items: flex-start;
    padding-top: 15px;
    border-color: #DADBDD;
    border-style: solid;
    border-width: 1px;
    padding-left: 20px;
    border-radius: 10px;
    padding-right: 20px;
    padding-bottom: 15px;
  }
  .subcomponent-paymentitem2:hover {
    border-color: #705CF6;
  }
  .subcomponent-radiocontainer2 {
    flex: 0 0 auto;
    width: 20px;
    cursor: pointer;
    height: 20px;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
  }
  .subcomponent-radiobutton2 {
    width: 100%;
    height: 100%;
  }
  .subcomponent-cardcontainer2 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .subcomponent-text5 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .subcomponent-cardsicon1 {
    width: 300px;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 10px;
    border-color: #DADBDD;
    border-width: 1px;
    padding-left: 15px;
    border-radius: 5px;
    padding-right: 15px;
    flex-direction: row;
    padding-bottom: 10px;
    justify-content: flex-start;
  }
  .subcomponent-image {
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-right: 15px;
  }
  .subcomponent-image1 {
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-right: 15px;
  }
  .subcomponent-image2 {
    width: 25px;
    height: 25px;
    object-fit: cover;
  }
  .subcomponent-redirect {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 30px;
    flex-direction: column;
    padding-bottom: 30px;
    justify-content: flex-start;
  }
  .subcomponent-pasted-image2 {
    width: 324px;
    margin-bottom: 50px;
  }
  .subcomponent-text6 {
    color: rgb(31, 39, 48);
    font-size: 24px;
    margin-bottom: 20px;
  }
  .subcomponent-text7 {
    color: rgb(31, 39, 48);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  