.verifyemail-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.verifyemail-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.verifyemail-text {
  color: var(--dl-color-gray-black);
}
.verifyemail-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}
.verifyemail-container3 {
  flex: 0 0 auto;
  width: 500px;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.verifyemail-container4 {
  flex: 0 0 auto;
  width: 50%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verifyemail-textinput {
  width: 100%;
  height: 40px;
  text-align: center;
  border-color: #c5c5c5;
  outline-color: var(--dl-color-primary-300);
}
.verifyemail-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: 30px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  background-color: #0074F0;
  justify-content: center;
}
.verifyemail-text2 {
  color: var(--dl-color-primary-300);
  cursor: pointer;
}
.verifyemail-button {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  margin-top: 30px;
  border-width: 0px;
  background-color: var(--dl-color-primary-300);
}
