.home-container {
  width: 100%;
  display: flex;
  overflow: visible;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: 30px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 30px;
  justify-content: space-between;
}
.home-logo {
  color: #711755;
  font-size: 24px;
}
.home-navbar-container {
  width: 350px;
  display: flex;
  position: relative;
}
.home-navbar {
  width: 464px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-link {
  color: rgb(81, 87, 94);
  cursor: pointer;
  text-decoration: none;
}
.home-link01 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  text-decoration: none;
}
.home-link02 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  text-decoration: none;
}
.home-navlink {
  color: #51575E;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-sign-in {
  color: #51575E;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 0px;
  border-width: 0px;
  margin-right: 0px;
  text-decoration: none;
  background-color: transparent;
}
.home-register {
  color: #51575E;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-color: #51575E;
  border-radius: 5px;
  text-decoration: none;
  background-color: transparent;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo1 {
  color: #711755;
  font-size: 24px;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-navbar1 {
  width: 464px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-link03 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  text-decoration: none;
}
.home-link04 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  text-decoration: none;
}
.home-link05 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  text-decoration: none;
}
.home-navlink1 {
  color: #51575E;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}
.home-btn-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-sign-in1 {
  color: #51575E;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 0px;
  border-width: 0px;
  margin-right: 0px;
  text-decoration: none;
  background-color: transparent;
}
.home-register1 {
  color: #51575E;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-color: #51575E;
  border-radius: 5px;
  text-decoration: none;
  background-color: transparent;
}
.home-offer {
  color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 500;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: rgb(112, 92, 246);
}
.home-text {
  color: #51575E;
  width: 100%;
  height: auto;
  font-size: 35px;
  align-self: center;
  margin-top: 60px;
  text-align: center;
}
.home-text005 {
  color: #83878C;
  width: 900px;
  font-size: 20px;
  font-style: normal;
  margin-top: 30px;
  text-align: center;
  font-weight: 500;
  padding-left: 0px;
  padding-right: 0px;
}
.home-link06 {
  color: #FFFFFF;
  width: 200px;
  cursor: pointer;
  font-style: normal;
  margin-top: 50px;
  text-align: center;
  font-weight: 500;
  margin-left: 0px;
  border-color: #705CF6;
  border-width: 2px;
  border-radius: 30px;
  margin-bottom: 107px;
  text-decoration: none;
  background-color: #705CF6;
}
.home-text011 {
  color: rgb(81, 87, 94);
  width: 100%;
  height: 80px;
  font-size: 30px;
  font-style: normal;
  margin-top: 70px;
  text-align: center;
  font-weight: 700;
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  margin-bottom: 20px;
}
.home-text013 {
  color: #705cf6;
}
.home-text014 {
  color: #705cf6;
}
.home-text015 {
  color: #705cf6;
}
.home-underlinesvg {
  width: 116px;
  height: 6px;
  margin-bottom: 100px;
}
.home-mobilefeatures {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-featureitem {
  flex: 1;
  width: auto;
  height: 190px;
  display: none;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  margin-left: 30px;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text017 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-featureitem01 {
  flex: 1;
  width: auto;
  height: 190px;
  display: none;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  margin-left: 30px;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image01 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading01 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text018 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-featureitem02 {
  flex: 1;
  width: auto;
  height: 190px;
  display: none;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard02 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image02 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading02 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text019 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-featureitem03 {
  flex: 1;
  width: auto;
  height: 190px;
  display: none;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  margin-left: 30px;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image03 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading03 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text020 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-featureitem04 {
  flex: 1;
  width: auto;
  height: 190px;
  display: none;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image04 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading04 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text021 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-featureitem05 {
  flex: 1;
  width: auto;
  height: 190px;
  display: none;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  margin-left: 30px;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image05 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading05 {
  color: #83878C;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text022 {
  color: #83878C;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-desktopfeatures {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 81px;
  padding-right: 81px;
  flex-direction: column;
  justify-content: center;
}
.home-topmiddlefeatureitem {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
}
.home-dummy-featureitem {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  align-items: flex-start;
}
.home-featureitem06 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  margin-left: 30px;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image06 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading06 {
  color: #83878C;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text023 {
  color: #83878C;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-dummy-featureitem1 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
}
.home-top-features {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-featureitem07 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard05 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image07 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading07 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text024 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-dummy-featureitem2 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
}
.home-featureitem08 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  margin-left: 30px;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image08 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading08 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text025 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-outersquare {
  flex: 0 0 auto;
  width: 746px;
  height: 746px;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1.963px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: flex-start;
}
.home-top-container {
  flex: 0 0 auto;
  width: 100%;
  height: 86px;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-innercircle {
  flex: 0 0 auto;
  width: 574px;
  height: 574px;
  display: flex;
  padding-top: 42px;
  border-color: #705CF6;
  border-style: solid;
  border-width: 1.511px;
  border-radius: var(--dl-radius-radius-round);
  justify-content: center;
}
.home-biggerrectangle {
  flex: 0 0 auto;
  width: 845px;
  height: 401px;
  display: flex;
  box-shadow: 6.08307px 6.08307px 20.27689px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-end;
  border-color: #705CF6;
  border-style: solid;
  border-width: 1.896px;
  border-radius: 10.138px;
  background-size: cover;
  background-image: url("/contact-500h.jpg");
}
.home-smallerrectanglecontainer {
  flex: 0 0 auto;
  width: auto;
  height: 50%;
  display: flex;
  align-items: flex-start;
  margin-left: 362px;
}
.home-smallerrectangle {
  flex: 0 0 auto;
  width: 589.044px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 276.574px;
  display: flex;
  box-shadow: 6.08307px 6.08307px 20.27689px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-end;
  border-color: #705CF6;
  border-style: solid;
  border-width: 1.896px;
  border-radius: 10.138px;
  background-size: cover;
  background-image: url("/livechat-1500h.jpg");
}
.home-bottom-container {
  flex: 0 0 auto;
  width: 100%;
  height: 86px;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.home-bottom-features {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.home-featureitem09 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image09 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading09 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text026 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-dummy-featureitem3 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
}
.home-featureitem10 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  margin-left: 30px;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image10 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading10 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text027 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-bottommiddlefeatureitem {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-end;
}
.home-dummy-featureitem4 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  align-items: flex-start;
}
.home-featureitem11 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  box-shadow: -6px 6px 20px 0px rgba(112, 92, 246, 0.25);
  align-items: flex-start;
  margin-left: 30px;
  border-color: #705CF6;
  border-width: 1.87px ;
  border-radius: 5px;
  flex-direction: column;
}
.home-featureitemcard09 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image11 {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 5px;
}
.home-heading11 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 5px;
}
.home-text028 {
  color: rgb(131, 135, 140);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.home-dummy-featureitem5 {
  flex: 1;
  width: auto;
  height: 190px;
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
}
.home-pricing {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.home-text029 {
  color: rgb(81, 87, 94);
  font-size: 30px;
  text-align: center;
}
.home-underlinesvg1 {
  width: 116px;
  height: 6px;
  margin-bottom: 40px;
}
.home-plans {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
}
.home-planitem {
  width: 280px;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 60px;
  border-color: #DADBDD;
  border-width: 2px;
  border-radius: 10px;
  flex-direction: column;
  padding-bottom: 60px;
  justify-content: flex-start;
}
.home-text035 {
  color: rgb(31, 39, 48);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}
.home-text036 {
  color: rgb(31, 39, 48);
  font-size: 40px;
  margin-top: 40px;
}
.home-link07 {
  color: #705cf6;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  margin-top: 37px;
  font-weight: 600;
  border-color: #705CF6;
  padding-left: 38px;
  border-radius: 30px;
  margin-bottom: 40px;
  padding-right: 38px;
  text-decoration: none;
}
.home-features {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-featureitem12 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image {
  width: 18px;
  height: 12px;
}
.home-text037 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem13 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image01 {
  width: 18px;
  height: 12px;
}
.home-text038 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem14 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image02 {
  width: 18px;
  height: 12px;
}
.home-text039 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem15 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image03 {
  width: 18px;
  height: 12px;
}
.home-text040 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem16 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image04 {
  width: 18px;
  height: 12px;
}
.home-text041 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem17 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image05 {
  width: 18px;
  height: 12px;
}
.home-text042 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem18 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image06 {
  width: 18px;
  height: 12px;
}
.home-text043 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem19 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-pasted-image07 {
  width: 18px;
  height: 12px;
}
.home-text044 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-planitem1 {
  width: 280px;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-top: 60px;
  border-color: #DADBDD;
  border-width: 2px;
  border-radius: 10px;
  flex-direction: column;
  padding-bottom: 60px;
  justify-content: flex-start;
}
.home-text045 {
  color: rgb(31, 39, 48);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}
.home-text046 {
  color: rgb(31, 39, 48);
  font-size: 40px;
  margin-top: 40px;
}
.home-link08 {
  color: #705cf6;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  margin-top: 37px;
  font-weight: 600;
  border-color: #705CF6;
  padding-left: 38px;
  border-radius: 30px;
  margin-bottom: 40px;
  padding-right: 38px;
  text-decoration: none;
}
.home-features1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-featureitem20 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image08 {
  width: 18px;
  height: 12px;
}
.home-text047 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem21 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image09 {
  width: 18px;
  height: 12px;
}
.home-text048 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem22 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image10 {
  width: 18px;
  height: 12px;
}
.home-text049 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem23 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image11 {
  width: 18px;
  height: 12px;
}
.home-text050 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem24 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image12 {
  width: 18px;
  height: 12px;
}
.home-text051 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem25 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image13 {
  width: 18px;
  height: 12px;
}
.home-text052 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem26 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image14 {
  width: 18px;
  height: 12px;
}
.home-text053 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem27 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-pasted-image15 {
  width: 18px;
  height: 12px;
}
.home-text054 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-planitem2 {
  width: 280px;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-top: 60px;
  border-color: #DADBDD;
  border-width: 2px;
  border-radius: 10px;
  flex-direction: column;
  padding-bottom: 60px;
  justify-content: flex-start;
}
.home-text055 {
  color: rgb(31, 39, 48);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}
.home-text056 {
  color: rgb(31, 39, 48);
  font-size: 40px;
  margin-top: 40px;
}
.home-link09 {
  color: #705cf6;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  margin-top: 37px;
  font-weight: 600;
  border-color: #705CF6;
  padding-left: 38px;
  border-radius: 30px;
  margin-bottom: 40px;
  padding-right: 38px;
  text-decoration: none;
}
.home-features2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-featureitem28 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image16 {
  width: 18px;
  height: 12px;
}
.home-text057 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem29 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image17 {
  width: 18px;
  height: 12px;
}
.home-text058 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem30 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image18 {
  width: 18px;
  height: 12px;
}
.home-text059 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem31 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image19 {
  width: 18px;
  height: 12px;
}
.home-text060 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem32 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image20 {
  width: 18px;
  height: 12px;
}
.home-text061 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem33 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image21 {
  width: 18px;
  height: 12px;
}
.home-text062 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem34 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-pasted-image22 {
  width: 18px;
  height: 12px;
}
.home-text063 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem35 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-pasted-image23 {
  width: 18px;
  height: 12px;
}
.home-text064 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-planitem3 {
  width: 280px;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-top: 60px;
  border-color: #DADBDD;
  border-width: 2px;
  border-radius: 10px;
  flex-direction: column;
  padding-bottom: 60px;
  justify-content: flex-start;
  background-color: #705CF6;
}
.home-text065 {
  color: white;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}
.home-text066 {
  color: white;
  font-size: 40px;
  margin-top: 40px;
}
.home-link10 {
  color: #705cf6;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  margin-top: 37px;
  font-weight: 600;
  border-color: #705CF6;
  padding-left: 38px;
  border-radius: 30px;
  margin-bottom: 40px;
  padding-right: 38px;
  text-decoration: none;
}
.home-features3 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-featureitem36 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text067 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem37 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text068 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem38 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text069 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem39 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text070 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem40 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text071 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem41 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text072 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem42 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text073 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-featureitem43 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-text074 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-addons {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.home-text075 {
  color: rgb(81, 87, 94);
  height: auto;
  font-size: 30px;
  text-align: center;
  margin-bottom: 15px;
}
.home-container4 {
  flex: 0 0 auto;
  width: 70px;
  height: 5px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 35px;
  background-color: #51575E;
}
.home-plans1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
}
.home-planitem4 {
  width: 420px;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 60px;
  border-color: #DADBDD;
  border-width: 2px;
  border-radius: 10px;
  flex-direction: column;
  padding-bottom: 60px;
  justify-content: center;
}
.home-features4 {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-featureitem44 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text076 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-text077 {
  font-size: 30px;
}
.home-text078 {
  font-size: 18px;
}
.home-featureitem45 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text079 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-text080 {
  font-size: 30px;
}
.home-text081 {
  font-size: 18px;
}
.home-featureitem46 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.home-text082 {
  color: rgb(81, 87, 94);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}
.home-text083 {
  font-size: 30px;
}
.home-text084 {
  font-size: 18px;
}
.home-faqs-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: none;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-faqs {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  justify-content: center;
}
.home-text085 {
  color: rgb(81, 87, 94);
  width: 100%;
  font-size: 30px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
}
.home-underlinesvg2 {
  width: 116px;
  height: 6px;
  margin-top: 20px;
  margin-bottom: 60px;
}
.home-thq-dropdown {
  width: 100%;
  cursor: pointer;
  height: 80px;
  display: inline-block;
  position: relative;
  align-self: center;
  border-color: #83878C;
  border-width: 0px;
  border-radius: 10px;
}
.home-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 80%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: #83878C;
  border-width: 1px;
  padding-left: 4%;
  border-radius: 10px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.home-text086 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 800;
  margin-right: 4px;
  vertical-align: middle;
}
.home-dropdown-arrow {
  transition: 0.3s;
}
.home-icon04 {
  width: 41px;
  height: 41px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}
.home-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.home-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle01:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text089 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle02:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text090 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle03:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text091 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-thq-dropdown1 {
  width: 100%;
  cursor: pointer;
  height: 80px;
  display: inline-block;
  position: relative;
  align-self: center;
  border-color: #83878C;
  border-width: 0px;
  border-radius: 10px;
}
.home-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 80%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: #83878C;
  border-width: 1px;
  padding-left: 4%;
  border-radius: 10px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.home-text092 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 800;
  margin-right: 4px;
  vertical-align: middle;
}
.home-dropdown-arrow1 {
  transition: 0.3s;
}
.home-icon06 {
  width: 41px;
  height: 41px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}
.home-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.home-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text095 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text096 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle07:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text097 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-thq-dropdown2 {
  width: 100%;
  cursor: pointer;
  height: 80px;
  display: inline-block;
  position: relative;
  align-self: center;
  border-color: #83878C;
  border-width: 0px;
  border-radius: 10px;
}
.home-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 80%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: #83878C;
  border-width: 1px;
  padding-left: 4%;
  border-radius: 10px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.home-text098 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 800;
  margin-right: 4px;
  vertical-align: middle;
}
.home-dropdown-arrow2 {
  transition: 0.3s;
}
.home-icon08 {
  width: 41px;
  height: 41px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}
.home-dropdown-list2 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.home-dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text101 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown7 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle10:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text102 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown8 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle11:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text103 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-banner {
  flex: 0 0 auto;
  width: 66%;
  height: 28%;
  display: flex;
  align-self: center;
  margin-top: 80px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  margin-bottom: 143px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #111827;
}
.home-text104 {
  color: #FFFFFF ;
  width: auto;
  font-size: 2.5em;
  align-self: center;
  margin-top: 40px;
  text-align: center;
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
}
.home-text105 {
  font-weight: 700;
}
.home-text106 {
  font-weight: 700;
}
.home-text107 {
  font-style: normal;
}
.home-text108 {
  color: #FFFFFF ;
  width: 100%;
  height: 22%;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  margin-top: 30px;
  text-align: center;
  font-weight: 700;
}
.home-link11 {
  color: #FFFFFF;
  cursor: pointer;
  font-size: 19px;
  align-self: center;
  font-style: normal;
  margin-top: 30px;
  text-align: center;
  font-weight: 700;
  border-color: #705CF6;
  border-width: 2px;
  border-radius: 12px;
  margin-bottom: 59px;
  text-decoration: none;
  background-color: #705CF6;
}
.home-footer {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-logo2 {
  color: #711755;
  font-size: 24px;
}
.home-text111 {
  color: #51575e;
  font-size: 14px;
  margin-top: 25px;
  text-align: center;
}
.home-text117 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  margin-top: 30px;
  font-weight: 600;
}
.home-text119 {
  color: #705cf6;
}
.home-text120 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  margin-top: 30px;
  font-weight: 600;
}
.home-text121 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 24px;
}
@media(max-width: 991px) {
  .home-featureitem {
    height: 197px;
  }
  .home-featureitem01 {
    height: 197px;
  }
  .home-featureitem02 {
    height: 197px;
  }
  .home-featureitem03 {
    height: 197px;
  }
  .home-featureitem04 {
    height: 197px;
  }
  .home-featureitem05 {
    height: 197px;
  }
  .home-desktopfeatures {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .home-dummy-featureitem {
    height: 197px;
  }
  .home-featureitem06 {
    height: 197px;
  }
  .home-container3 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .home-dummy-featureitem1 {
    height: 197px;
  }
  .home-featureitem07 {
    height: 197px;
  }
  .home-dummy-featureitem2 {
    height: 197px;
  }
  .home-featureitem08 {
    height: 197px;
  }
  .home-featureitem09 {
    height: 197px;
  }
  .home-dummy-featureitem3 {
    height: 197px;
  }
  .home-featureitem10 {
    height: 197px;
  }
  .home-dummy-featureitem4 {
    height: 197px;
  }
  .home-featureitem11 {
    height: 197px;
  }
  .home-dummy-featureitem5 {
    height: 197px;
  }
  .home-faqs {
    padding-left: 62px;
    padding-right: 62px;
  }
}
@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-navbar-container {
    display: none;
  }
  .home-navbar {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-link {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link01 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link02 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-navlink {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-mobile-menu {
    height: auto;
    display: none;
    justify-content: flex-start;
  }
  .home-navbar1 {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-link03 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link04 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link05 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group1 {
    justify-content: flex-start;
  }
  .home-text {
    height: auto;
  }
  .home-text005 {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-text011 {
    height: auto;
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-mobilefeatures {
    gap: 16px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }
  .home-featureitem {
    display: flex;
    margin-left: 0px;
    margin-right: 30px;
  }
  .home-featureitemcard {
    width: 100%;
  }
  .home-featureitem01 {
    display: flex;
    margin-left: 30px;
  }
  .home-featureitemcard01 {
    margin-right: 30px;
  }
  .home-featureitem02 {
    display: flex;
    margin-right: 30px;
  }
  .home-featureitem03 {
    display: flex;
    margin-left: 30px;
  }
  .home-featureitem04 {
    display: flex;
    margin-right: 30px;
  }
  .home-featureitem05 {
    display: flex;
    margin-left: 30px;
  }
  .home-desktopfeatures {
    display: none;
  }
  .home-plans {
    align-items: center;
    flex-direction: column;
  }
  .home-planitem {
    width: 320px;
    margin-bottom: 20px;
  }
  .home-planitem1 {
    width: 320px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .home-planitem2 {
    width: 320px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .home-planitem3 {
    width: 320px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .home-plans1 {
    align-items: center;
    flex-direction: column;
  }
  .home-planitem4 {
    width: 320px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-navbar-container {
    display: none;
    flex-direction: row;
  }
  .home-navbar {
    flex-direction: row;
  }
  .home-mobile-menu {
    display: none;
    padding: 16px;
  }
  .home-navbar1 {
    flex-direction: column;
  }
  .home-text {
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-text029 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-plans {
    width: auto;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }
  .home-planitem {
    width: 320px;
    height: auto;
    margin-top: 0px;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 30px;
    justify-content: center;
  }
  .home-planitem1 {
    width: 320px;
    height: auto;
    margin-top: 0px;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 30px;
    justify-content: center;
  }
  .home-planitem2 {
    width: 320px;
    height: auto;
    margin-top: 0px;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 30px;
    justify-content: center;
  }
  .home-planitem3 {
    width: 320px;
    height: auto;
    margin-top: 0px;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 30px;
    justify-content: center;
  }
  .home-text075 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-plans1 {
    width: auto;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }
  .home-planitem4 {
    width: 320px;
    height: auto;
    margin-top: 0px;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 30px;
    justify-content: center;
  }
  .home-faqs {
    padding-left: 5px;
    padding-right: 5px;
  }
}
