.settings-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  flex-direction: column;
  background-color: #f7f7f7;
}
.settings-container1 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.settings-sidebar {
  flex: 0 0 auto;
  width: 150px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.settings-sidebarheader {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.settings-logotext {
  color: purple;
}
.settings-sidebaritemcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.settings-buttons-wrapper {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-navlink {
  display: contents;
}
.settings-sidebaritem {
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
  text-decoration: none;
}
.settings-sidebaritem:hover {
  background-color: #D9D9D9;
}
.settings-sidebaritem:active {
  background-color: #D9D9D9;
}
.settings-whatsappicon {
  fill: #585b5b;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.settings-sidebaritemtext {
  color: #585B5B;
  font-style: normal;
  font-weight: 500;
}
.settings-navlink1 {
  display: contents;
}
.settings-sidebaritem1 {
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
  text-decoration: none;
  background-color: #74e1bc;
}
.settings-sidebaritem1:hover {
  background-color: #D9D9D9;
}
.settings-contacticon {
  fill: #585B5B;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.settings-sidebaritemtext1 {
  color: #585B5B;
  font-style: normal;
  font-weight: 500;
}
.settings-navlink2 {
  display: contents;
}
.settings-sidebaritem2 {
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
  text-decoration: none;
}
.settings-sidebaritem2:hover {
  background-color: #D9D9D9;
}
.settings-chaticon {
  fill: #585b5b;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.settings-sidebaritemtext2 {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 500;
}
.settings-logout-wrapper {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.settings-sidebaritem3 {
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
}
.settings-sidebaritem3:hover {
  background-color: #D9D9D9;
}
.settings-sidebaritem3:active {
  background-color: #D9D9D9;
}
.settings-logouticon {
  fill: #585B5B;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.settings-logouttext {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 500;
}
.settings-body {
  flex: 0 0 auto;
  width: calc(100% - 150px);
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  background-color: #F3F3F3;
}
.settings-header {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 30px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.settings-subscription-subscription-plan {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  margin-top: 30px;
  align-items: flex-start;
  padding-left: 30px;
  margin-bottom: 20px;
  padding-right: 30px;
  flex-direction: column;
}
.settings-nav-bar {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  margin-bottom: 60px;
}
.settings-header-item {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item01 {
  color: #705cf6;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item02 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item03 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item04 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item05 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item06 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.settings-sub-header {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 10px;
  padding-right: 30px;
  justify-content: space-between;
  background-color: white;
}
.settings-btn-group {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings-text02 {
  color: rgb(31, 39, 48);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.settings-text03 {
  color: rgb(131, 135, 140);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.settings-button {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  border-radius: 5px;
  padding-right: 40px;
  flex-direction: row;
  justify-content: center;
  background-color: #705CF6;
}
.settings-pasted-image {
  width: 28px;
  height: 28px;
}
.settings-text04 {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
}
.settings-sub-body {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 40px;
  background-color: white;
}
.settings-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 56px;
  justify-content: space-between;
}
.settings-heading1 {
  color: rgb(31, 39, 48);
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.settings-heading2 {
  color: #705CF6;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.settings-table {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-table-row {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: space-between;
  background-color: #F8F6F7;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-table-column {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text05 {
  color: #83878C;
  font-style: normal;
  font-weight: 500;
}
.settings-table-column01 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text06 {
  color: rgb(131, 135, 140);
  font-style: normal;
  font-weight: 500;
}
.settings-table-column02 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text07 {
  color: rgb(131, 135, 140);
  font-style: normal;
  font-weight: 500;
}
.settings-table-column03 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text08 {
  color: rgb(131, 135, 140);
  font-style: normal;
  font-weight: 500;
}
.settings-table-row1 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-table-column04 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text09 {
  color: #1F2730;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.settings-table-column05 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text10 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.settings-table-column06 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text11 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.settings-table-column07 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-icon04 {
  fill: #B4B7BA;
  width: 44px;
  cursor: pointer;
  height: 22px;
}
.settings-icon06 {
  fill: #705CF6;
  width: 44px;
  cursor: pointer;
  height: 22px;
}
.settings-table-row2 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.settings-table-column08 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text12 {
  color: #1F2730;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.settings-table-column09 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text13 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.settings-table-column10 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-text14 {
  color: rgb(31, 39, 48);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.settings-table-column11 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  justify-content: center;
}
.settings-icon08 {
  fill: #B4B7BA;
  width: 44px;
  cursor: pointer;
  height: 22px;
  display: none;
}
.settings-icon10 {
  fill: #705CF6;
  width: 44px;
  cursor: pointer;
  height: 22px;
}
.settings-subscription-payment-method {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: none;
  flex-grow: 1;
  margin-top: 30px;
  align-items: flex-start;
  padding-left: 30px;
  margin-bottom: 20px;
  padding-right: 30px;
  flex-direction: column;
}
.settings-nav-bar1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  margin-bottom: 60px;
}
.settings-header-item07 {
  color: rgb(81, 87, 94);
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item08 {
  color: #705cf6;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item09 {
  color: rgb(81, 87, 94);
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item10 {
  color: rgb(81, 87, 94);
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item11 {
  color: rgb(81, 87, 94);
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item12 {
  color: rgb(81, 87, 94);
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item13 {
  color: rgb(81, 87, 94);
  font-size: 16px;
  font-weight: 500;
}
.settings-sub-header1 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 10px;
  padding-right: 30px;
  justify-content: space-between;
  background-color: white;
}
.settings-btn-group1 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings-text17 {
  color: rgb(131, 135, 140);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.settings-text18 {
  color: rgb(31, 39, 48);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 70px;
}
.settings-button1 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  border-radius: 5px;
  padding-right: 40px;
  flex-direction: row;
  justify-content: center;
  background-color: #705CF6;
}
.settings-pasted-image1 {
  width: 28px;
  height: 28px;
}
.settings-text19 {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
}
.settings-sub-body1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 40px;
  background-color: white;
}
.settings-head {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 56px;
  flex-direction: column;
  justify-content: space-between;
}
.settings-heading3 {
  color: rgb(31, 39, 48);
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 14px;
}
.settings-heading4 {
  color: rgb(131, 135, 140);
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.settings-card {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  border-color: #DADBDD;
  border-width: 1px;
  padding-left: 60px;
  border-radius: 5px;
  padding-right: 60px;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
}
.settings-card-detail {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.settings-card-logo {
  flex: 0 0 auto;
  width: 110px;
  height: 45px;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  margin-right: 20px;
  border-radius: 5px;
  justify-content: center;
}
.settings-pasted-image2 {
  width: 80px;
  height: 80px;
}
.settings-card-digits {
  flex: 0 0 auto;
  width: 500px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-text20 {
  color: rgb(31, 39, 48);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.settings-text21 {
  color: #83878C;
  font-size: 14px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
}
.settings-text22 {
  color: #83878C;
  font-size: 14px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
}
.settings-edit-btn {
  color: #1F2730;
  cursor: pointer;
  border-color: #DADBDD;
  padding-left: 38px;
  padding-right: 38px;
}
.settings-subscription-payment-method1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: none;
  flex-grow: 1;
  margin-top: 30px;
  align-items: flex-start;
  padding-left: 30px;
  margin-bottom: 20px;
  padding-right: 30px;
  flex-direction: column;
}
.settings-nav-bar2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  margin-bottom: 60px;
}
.settings-header-item14 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item15 {
  color: #705cf6;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item16 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item17 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item18 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item19 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-right: 50px;
}
.settings-header-item20 {
  color: rgb(81, 87, 94);
  font-size: 16px;
  font-weight: 500;
}
.settings-sub-header2 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 10px;
  padding-right: 30px;
  justify-content: space-between;
  background-color: white;
}
.settings-btn-group2 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings-text25 {
  color: rgb(131, 135, 140);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.settings-text26 {
  color: rgb(31, 39, 48);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 70px;
}
.settings-button2 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  border-radius: 5px;
  padding-right: 40px;
  flex-direction: row;
  justify-content: center;
  background-color: #705CF6;
}
.settings-pasted-image3 {
  width: 28px;
  height: 28px;
}
.settings-text27 {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
}
.settings-sub-body2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 40px;
  background-color: white;
}
.settings-head1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 56px;
  flex-direction: column;
  justify-content: space-between;
}
.settings-heading5 {
  color: rgb(31, 39, 48);
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 14px;
}
.settings-heading6 {
  color: rgb(131, 135, 140);
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.settings-card1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  border-color: #DADBDD;
  border-width: 1px;
  padding-left: 60px;
  border-radius: 5px;
  padding-right: 60px;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
}
.settings-card-detail1 {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.settings-card-logo1 {
  flex: 0 0 auto;
  width: 110px;
  height: 45px;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  margin-right: 20px;
  border-radius: 5px;
  justify-content: center;
}
.settings-pasted-image4 {
  width: 80px;
  height: 80px;
}
.settings-card-digits1 {
  flex: 0 0 auto;
  width: 500px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.settings-text28 {
  color: rgb(31, 39, 48);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.settings-text29 {
  color: #83878C;
  font-size: 14px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
}
.settings-text30 {
  color: #83878C;
  font-size: 14px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
}
.settings-edit-btn1 {
  color: #1F2730;
  cursor: pointer;
  border-color: #DADBDD;
  padding-left: 38px;
  padding-right: 38px;
}


.credit-balance {
  flex: 0 0 auto;
  width: 326px;
  height: 70px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  padding-top: 10px;
  padding-left: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: white;
}
.credit-creditbalance {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.credit-text {
  color: #83878C;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
}
.credit-text1 {
  color: rgb(31, 39, 48);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.credit-button {
  color: white;
  cursor: pointer;
  margin-left: 60px;
  border-width: 0px;
  background-color: #705CF6;
}


@media(max-width: 991px) {
  .settings-body {
    width: calc(100% - 150px);
  }
}
@media(max-width: 767px) {
  .settings-container1 {
    min-width: auto;
  }
  .settings-body {
    width: calc(100% - 150px);
  }
}
@media(max-width: 479px) {
  .settings-logotext {
    font-size: 14px;
  }
  .settings-body {
    width: calc(100% - 150px);
  }
  .settings-header {
    width: 100%;
  }
  .settings-heading {
    font-size: 13px;
  }
  .settings-subscription-subscription-plan {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .settings-subscription-payment-method {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .settings-subscription-payment-method1 {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.data-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.data-datagroup {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 50px;
  padding-left: 100px;
  border-radius: 10px;
  padding-right: 100px;
  flex-direction: column;
  padding-bottom: 50px;
  background-color: white;
}
.data-text {
  color: rgb(31, 39, 48);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 40px;
  cursor: pointer;
}
.data-table {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.data-row {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  border-color: #DADBDD;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: #F8F6F7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.data-rowitem {
  flex: 0 0 auto;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.data-rowitem01 {
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text01 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-rowitem02 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text02 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-rowitem03 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text03 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-container1 {
  flex: 0 0 auto;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.data-row1 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  border-color: #DADBDD;
  border-width: 1px;
  border-top-width: 0px;
  padding-left: 20px;
  padding-right: 20px;
}
.data-rowitem04 {
  flex: 0 0 auto;
  width: 30px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.data-icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.data-rowitem05 {
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text04 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-rowitem06 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text05 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-rowitem07 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text06 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-container2 {
  flex: 0 0 auto;
  width: 30px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-icon2 {
  fill: #1F2730;
  width: 24px;
  height: 24px;
}
.data-container3 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  border-color: #705CF6;
  border-width: 1px;
  justify-content: center;
}
.data-text07 {
  color: rgb(112, 92, 246);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-dataitems {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 50px;
  padding-left: 100px;
  border-radius: 10px;
  padding-right: 100px;
  flex-direction: column;
  padding-bottom: 50px;
  background-color: white;
}
.data-text08 {
  color: rgb(31, 39, 48);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 40px;
}
.data-table1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.data-row2 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  border-color: #DADBDD;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: #F8F6F7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.data-rowitem08 {
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text09 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-rowitem09 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text10 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-rowitem10 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text11 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-row3 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  border-color: #DADBDD;
  border-width: 1px;
  border-top-width: 0px;
}
.data-rowitem11 {
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text12 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-rowitem12 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-text13 {
  color: rgb(131, 135, 140);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.data-rowitem13 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-icon4 {
  fill: rgb(131, 135, 140);
  width: 24px;
  cursor: pointer;
  height: 24px;
  transition: 0.3s;
}
.data-icon4:hover {
  fill: var(--dl-color-danger-700);
}
.data-container4 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  border-color: #705CF6;
  border-width: 1px;
  justify-content: center;
}
.data-text14 {
  color: rgb(112, 92, 246);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
