

.flexbox {
    display: flex;
    justify-content: flex-start;
    column-gap: 16px;
    background-color: #fff;
    padding: 24px;
    width: 100%;
}

.flexbox.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
}


.action-drawer-container {
    width: 100%;
}

.list-item {
    margin-bottom: 8px;
    box-shadow: 0 0px 8px rgba(0,0,0,.1);
    background-color: #fff;
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.para {
    font-size: .9rem;
    color: gray;
    margin-left: 1.5rem;
}

.para1 {
    font-size: .9rem;
    margin-top: 10px;
    color: rgb(57, 57, 57);
}