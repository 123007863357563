
.chatbots-sidebar {
  flex: 0 0 auto;
  width: 150px;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}

.chatbots-sidebarheader {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbots-logotext {
  color: purple;
}
.chatbots-sidebaritemcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  justify-content: space-between;
  
}
.chatbots-buttons-wrapper {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.chatbots-navlink {
  display: contents;
}
.chatbots-sidebaritem {
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
  text-decoration: none;
  margin-top: 10px;
  
}
.chatbots-sidebaritem:hover {
  background-color: #D9D9D9;
}
.chatbots-sidebaritem:active {
  background-color: #D9D9D9;
}
.chatbots-whatsappicon {
  fill: #585b5b;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.chatbots-sidebaritemtext {
  color: #585B5B;
  font-style: normal;
  font-weight: 500;
}
.chatbots-navlink1 {
  display: contents;
}


.chatbots-contacticon {
  fill: #585B5B;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.chatbots-navlink2 {
  display: contents;
}


.chatbots-chaticon {
  fill: #585b5b;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.chatbots-logout-wrapper {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}


.chatbots-logouticon {
  fill: #585B5B;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.chatbots-logouttext {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 500;
}

.active-link {
  background-color: red; /* Set the background color to red for the active link */
  /* Add any other styles you want for the active link */
}
