.testingpage-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  transition: 0.3s;
  border-color: #c5c5c5;
  border-style: dashed;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  margin-bottom: 10px;
}
.testingpage-container1:hover {
  border-color: var(--dl-color-primary-300);
  border-width: 2px;
}
.testingpage-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.testingpage-icon {
  fill: var(--dl-color-gray-500);
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.testingpage-text {
  line-height: 12px;
}
.testingpage-container3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testingpage-text1 {
  color: var(--dl-color-primary-300);
  margin-right: 5px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
}
.testingpage-text2 {
  margin-right: 5px;
  margin-left: 5px;
}
.testingpage-text3 {
  color: var(--dl-color-primary-300);
  cursor: pointer;
}
.testingpage-filecontainer {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  border-color: #c5c5c5;
  border-style: dashed;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.testingpage-filecontainer:hover {
  border-color: var(--dl-color-primary-300);
  border-width: 2px;
}
.testingpage-fiileheader {
  flex: 0 0 auto;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.testingpage-text {
  color: purple;
  margin-right: 5px;
}
.testingpage-filebody {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msgbtn-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.msgbtn-btncontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 15px;
}
.msgbtn-button {
  color: var(--dl-color-primary-300);
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  border-color: var(--dl-color-primary-300);
  margin-bottom: 5px;
}
.msgbtn-button:hover {
  background-color: #e1e6e9;
}
.msgbtn-button1 {
  color: #585b5b;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  border-color: #c5c5c5;
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 5px;
}
.msgbtn-button1:hover {
  font-size: 14px;
  border-color: var(--dl-color-primary-300);
  background-color: #e1e6e9;
}