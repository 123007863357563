.broadcast-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    background-color: #f7f7f7;
  }
  .broadcast-draft {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .broadcast-draftscontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    flex-direction: column;
  }
  .broadcast-flowitem {
    top: 0;
    flex: 0 0 auto;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    margin-bottom: 5px;
    padding-right: 10px;
    background-color: white;
  }
  .broadcast-namelabel {
    flex: 0 0 auto;
    width: 37%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .broadcast-text {
    font-style: normal;
    font-weight: 500;
  }
  .broadcast-lastmodifiedlabel {
    flex: 0 0 auto;
    width: 14%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .broadcast-text01 {
    font-style: normal;
    font-weight: 500;
  }
  .broadcast-flowitem1 {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 70px;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-left: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 5px;
    padding-right: 10px;
    padding-bottom: 0px;
    background-color: white;
  }
  .broadcast-namecontainer {
    flex: 0 0 auto;
    width: 37%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 15px;
  }
  .broadcast-text02 {
    overflow: hidden;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .broadcast-lastmodified {
    flex: 0 0 auto;
    width: 58%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .broadcast-text03 {
    font-style: normal;
    font-weight: 400;
  }
  .broadcast-actioncontainer {
    top: 0px;
    flex: 1;
    right: 0px;
    width: 5%;
    cursor: pointer;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }
  .broadcast-icon {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
  }
  .broadcast-scheduled {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .broadcast-scheduledcontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    flex-direction: column;
  }
  .broadcast-flowitem2 {
    top: 0;
    flex: 0 0 auto;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    margin-bottom: 5px;
    padding-right: 10px;
    background-color: white;
  }
  .broadcast-namelabel1 {
    flex: 0 0 auto;
    width: 37%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .broadcast-text04 {
    font-style: normal;
    font-weight: 500;
  }
  .broadcast-lastmodifiedlabel1 {
    flex: 0 0 auto;
    width: 14%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .broadcast-text05 {
    font-style: normal;
    font-weight: 500;
  }
  .broadcast-flowitem3 {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 70px;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-left: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 5px;
    padding-right: 10px;
    padding-bottom: 0px;
    background-color: white;
  }
  .broadcast-namecontainer1 {
    flex: 0 0 auto;
    width: 37%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 15px;
  }
  .broadcast-text06 {
    overflow: hidden;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .broadcast-lastmodified1 {
    flex: 0 0 auto;
    width: 58%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .broadcast-text07 {
    font-style: normal;
    font-weight: 400;
  }
  .broadcast-actioncontainer1 {
    top: 0px;
    flex: 1;
    right: 0px;
    width: 5%;
    cursor: pointer;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }
  .broadcast-icon2 {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
  }
  .broadcast-history {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .broadcast-historycontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    flex-direction: column;
  }
  .broadcast-flowitem4 {
    top: 0;
    flex: 0 0 auto;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    margin-bottom: 5px;
    padding-right: 10px;
    background-color: white;
  }
  .broadcast-namelabel2 {
    flex: 0 0 auto;
    width: 37%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .broadcast-text08 {
    font-style: normal;
    font-weight: 500;
  }
  .broadcast-lastmodifiedlabel2 {
    flex: 0 0 auto;
    width: 14%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .broadcast-text09 {
    font-style: normal;
    font-weight: 500;
  }
  .broadcast-lastmodifiedlabel3 {
    flex: 0 0 auto;
    width: 14%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .broadcast-text10 {
    font-style: normal;
    font-weight: 500;
  }
  .broadcast-lastmodifiedlabel4 {
    flex: 0 0 auto;
    width: 19%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .broadcast-text11 {
    font-style: normal;
    font-weight: 500;
  }
  .broadcast-flowitem5 {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 70px;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-left: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 5px;
    padding-right: 10px;
    padding-bottom: 0px;
    background-color: white;
  }
  .broadcast-namecontainer2 {
    flex: 0 0 auto;
    width: 37%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 15px;
  }
  .broadcast-text12 {
    overflow: hidden;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .broadcast-lastmodified2 {
    flex: 0 0 auto;
    width: 14%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .broadcast-text13 {
    font-style: normal;
    font-weight: 400;
  }
  .broadcast-actioncontainer2 {
    top: 0px;
    flex: 1;
    right: 0px;
    width: 5%;
    cursor: pointer;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }
  .broadcast-icon4 {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
  }
  .broadcast-lastmodifiedlabel5 {
    flex: 0 0 auto;
    width: 14%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .broadcast-text14 {
    font-style: normal;
    font-weight: 400;
  }
  .broadcast-lastmodifiedlabel6 {
    flex: 0 0 auto;
    width: 19%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .broadcast-text15 {
    font-style: normal;
    font-weight: 400;
  }
  .broadcast-emptybroadcast {
    top: 0;
    flex: 0 0 auto;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 40px;
    padding-right: 10px;
    justify-content: center;
    background-color: white;
  }
  .broadcast-text16 {
    color: var(--dl-color-gray-700);
    font-style: normal;
    font-weight: 400;
  }
  .broadcast-createnewbroadcast {
    top: 0;
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 55px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 40px;
    padding-right: 10px;
    justify-content: center;
    background-color: white;
  }
  .broadcast-text17 {
    color: var(--dl-color-primary-300);
    font-style: normal;
    font-weight: 400;
  }
  @media(max-width: 479px) {
    .broadcast-text {
      font-size: 14px;
    }
    .broadcast-text01 {
      font-size: 14px;
    }
    .broadcast-text02 {
      font-size: 12px;
    }
    .broadcast-text03 {
      font-size: 12px;
    }
    .broadcast-text04 {
      font-size: 14px;
    }
    .broadcast-text05 {
      font-size: 14px;
    }
    .broadcast-text06 {
      font-size: 12px;
    }
    .broadcast-text07 {
      font-size: 12px;
    }
    .broadcast-text08 {
      font-size: 14px;
    }
    .broadcast-text09 {
      font-size: 14px;
    }
    .broadcast-text10 {
      font-size: 14px;
    }
    .broadcast-text11 {
      font-size: 14px;
    }
    .broadcast-text12 {
      font-size: 12px;
    }
    .broadcast-text13 {
      font-size: 12px;
    }
    .broadcast-text14 {
      font-size: 12px;
    }
    .broadcast-text15 {
      font-size: 12px;
    }
    .broadcast-text16 {
      font-size: 12px;
    }
    .broadcast-text17 {
      font-size: 12px;
    }
  }
  