.livechat-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
}
.livechat-wholebody {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
}
.livechat-sidebar {
  flex: 0 0 auto;
  width: 150px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  flex-direction: column;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.livechat-sidebarheader {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.livechat-logotext {
  color: purple;
}
.livechat-sidebaritemcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.livechat-buttons-wrapper {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.livechat-navlink {
  display: contents;
}
.livechat-sidebaritem {
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
  text-decoration: none;
}
.livechat-sidebaritem:hover {
  background-color: #D9D9D9;
}
.livechat-sidebaritem:active {
  background-color: #D9D9D9;
}
.livechat-whatsappicon {
  fill: #585b5b;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.livechat-sidebaritemtext {
  color: #585B5B;
  font-style: normal;
  font-weight: 500;
}
.livechat-navlink1 {
  display: contents;
}
.livechat-sidebaritem1 {
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
  text-decoration: none;
}
.livechat-sidebaritem1:hover {
  background-color: #D9D9D9;
}
.livechat-contacticon {
  fill: #585B5B;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.livechat-sidebaritemtext1 {
  color: #585B5B;
  font-style: normal;
  font-weight: 500;
}
.livechat-navlink2 {
  display: contents;
}
.livechat-sidebaritem2 {
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
  text-decoration: none;
  background-color: #74e1bc;
}
.livechat-sidebaritem2:hover {
  background-color: #D9D9D9;
}
.livechat-chaticon {
  fill: #585b5b;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.livechat-sidebaritemtext2 {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 500;
}
.livechat-logout-wrapper {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.livechat-sidebaritem3 {
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: flex-start;
}
.livechat-sidebaritem3:hover {
  background-color: #D9D9D9;
}
.livechat-sidebaritem3:active {
  background-color: #D9D9D9;
}
.livechat-logouticon {
  fill: #585B5B;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.livechat-logouttext {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 500;
}
.livechat-body {
  flex: 0 0 auto;
  width: calc(100% - 150px);
  height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.livechat-header {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 7px;
  padding-right: 7px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.livechat-text {
  margin-left: 7px;
}
.livechat-icon04 {
  fill: 500 (Gray);
  width: 20px;
  cursor: pointer;
  height: 20px;
}
.livechat-body1 {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.livechat-assignee-section {
  width: 150px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 7px;
  padding-right: 7px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.livechat-conversations {
  flex: 0 0;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.livechat-conversation-header {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 0px;
  margin-bottom: 10px;
}
.livechat-text01 {
  color: rgb(88, 91, 91);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.livechat-conversation-item {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  justify-content: space-between;
}
.livechat-conversation-item:hover {
  background-color: #edf1f5;
}
.livechat-container01 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-icon06 {
  fill: #585B5B;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.livechat-text02 {
  color: rgb(88, 91, 91);
  font-size: 12px;
  margin-right: 5px;
}
.livechat-container02 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-container03 {
  flex: 0 0 auto;
  width: 5px;
  height: 5px;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-primary-300);
}
.livechat-text03 {
  color: rgb(88, 91, 91);
  font-size: 12px;
}
.livechat-conversation-item1 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  justify-content: space-between;
  background-color: #E0E4EA;
}
.livechat-conversation-item1:hover {
  background-color: #edf1f5;
}
.livechat-container04 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-icon08 {
  fill: #585B5B;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-bottom: 1px;
}
.livechat-text04 {
  color: #585B5B;
  font-size: 12px;
  margin-right: 5px;
}
.livechat-container05 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-container06 {
  flex: 0 0 auto;
  width: 5px;
  height: 5px;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-primary-300);
}
.livechat-text05 {
  color: rgb(88, 91, 91);
  font-size: 12px;
}
.livechat-conversation-item2 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  justify-content: space-between;
}
.livechat-conversation-item2:hover {
  background-color: #edf1f5;
}
.livechat-container07 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-image {
  width: 16px;
  height: 16px;
  object-fit: cover;
  margin-right: 4px;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-text06 {
  color: rgb(88, 91, 91);
  font-size: 12px;
  margin-right: 5px;
}
.livechat-container08 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-container09 {
  flex: 0 0 auto;
  width: 5px;
  height: 5px;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-primary-300);
}
.livechat-text07 {
  color: rgb(88, 91, 91);
  font-size: 12px;
}
.livechat-divider {
  flex: 0 0 auto;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 0px;
  margin-bottom: 25px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-bots {
  width: 100%;
  height: calc(100% - 215px);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.livechat-bot-header {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 0px;
  margin-bottom: 10px;
}
.livechat-text08 {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 500;
}
.livechat-bot-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: scroll;
  align-items: flex-start;
  flex-direction: column;
}
.livechat-bot-item {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  justify-content: space-between;
}
.livechat-name-icon {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-icon10 {
  fill: #585B5B;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.livechat-text09 {
  color: #585B5B;
  font-size: 14px;
  transition: 0.3s;
  margin-right: 5px;
}
.livechat-text09:hover {
  color: var(--dl-color-success-500);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.livechat-bot-item1 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  justify-content: space-between;
}
.livechat-name-icon1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-image01 {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 5px;
}
.livechat-text10 {
  color: rgb(88, 91, 91);
  width: auto;
  overflow: hidden;
  font-size: 14px;
  transition: 0.3s;
  margin-right: 5px;
  text-overflow: ellipsis;
}
.livechat-text10:hover {
  color: var(--dl-color-success-500);
  font-style: normal;
  font-weight: 500;
}
.livechat-bot-item2 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 10px;
  justify-content: space-between;
}
.livechat-name-icon2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-image02 {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 5px;
}
.livechat-text11 {
  color: rgb(88, 91, 91);
  width: auto;
  overflow: hidden;
  font-size: 14px;
  transition: 0.3s;
  white-space: nowrap;
  margin-right: 5px;
  text-overflow: ellipsis;
}
.livechat-text11:hover {
  color: var(--dl-color-success-500);
  font-style: normal;
  font-weight: 500;
}
.livechat-chat-section {
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  flex-direction: column;
  background-color: #F0F4F8;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.livechat-chat-header {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 5px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-search-bar {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-checkbox {
  margin-right: 10px;
}
.livechat-textinput {
  width: 150px;
  height: 24px;
  font-size: 12px;
  border-color: #c5c5c5;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  outline-color: var(--dl-color-primary-300);
}
.livechat-search-filter {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 0px;
  justify-content: flex-end;
}
.livechat-icon12 {
  fill: var(--dl-color-gray-500);
  width: 18px;
  cursor: pointer;
  height: 18px;
}
.livechat-image03 {
  width: 18px;
  cursor: pointer;
  height: 18px;
  object-fit: cover;
  margin-left: 3px;
}
.livechat-chat-container {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  max-height: calc(100% - 50px);
  overflow-y: scroll;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 10px;
}
.livechat-chat-item {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #E0E4EA;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox01 {
  margin-right: 5px;
}
.livechat-image04 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text12 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text13 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text14 {
  font-size: 10px;
}
.livechat-chat-item01 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item01:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox02 {
  margin-right: 5px;
}
.livechat-image05 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont01 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text15 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text16 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont01 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text17 {
  font-size: 10px;
}
.livechat-chat-item02 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item02:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox03 {
  margin-right: 5px;
}
.livechat-image06 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont02 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text18 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text19 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont02 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text20 {
  font-size: 10px;
}
.livechat-chat-item03 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item03:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox04 {
  margin-right: 5px;
}
.livechat-image07 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont03 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text21 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text22 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont03 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text23 {
  font-size: 10px;
}
.livechat-chat-item04 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item04:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox05 {
  margin-right: 5px;
}
.livechat-image08 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont04 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text24 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text25 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont04 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text26 {
  font-size: 10px;
}
.livechat-chat-item05 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item05:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox06 {
  margin-right: 5px;
}
.livechat-image09 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont05 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text27 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text28 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont05 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text29 {
  font-size: 10px;
}
.livechat-chat-item06 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item06:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox07 {
  margin-right: 5px;
}
.livechat-image10 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont06 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text30 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text31 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont06 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text32 {
  font-size: 10px;
}
.livechat-chat-item07 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item07:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox08 {
  margin-right: 5px;
}
.livechat-image11 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont07 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text33 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text34 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont07 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text35 {
  font-size: 10px;
}
.livechat-chat-item08 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item08:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox09 {
  margin-right: 5px;
}
.livechat-image12 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont08 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text36 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text37 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont08 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text38 {
  font-size: 10px;
}
.livechat-chat-item09 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item09:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox10 {
  margin-right: 5px;
}
.livechat-image13 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont09 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text39 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text40 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont09 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text41 {
  font-size: 10px;
}
.livechat-chat-item10 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item10:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox11 {
  margin-right: 5px;
}
.livechat-image14 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont10 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text42 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text43 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont10 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text44 {
  font-size: 10px;
}
.livechat-chat-item11 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item11:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox12 {
  margin-right: 5px;
}
.livechat-image15 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont11 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text45 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text46 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont11 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text47 {
  font-size: 10px;
}
.livechat-chat-item12 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item12:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox13 {
  margin-right: 5px;
}
.livechat-image16 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont12 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text48 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text49 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont12 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text50 {
  font-size: 10px;
}
.livechat-chat-item13 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item13:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox14 {
  margin-right: 5px;
}
.livechat-image17 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont13 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text51 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text52 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont13 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text53 {
  font-size: 10px;
}
.livechat-chat-item14 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item14:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox15 {
  margin-right: 5px;
}
.livechat-image18 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont14 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text54 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text55 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont14 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text56 {
  font-size: 10px;
}
.livechat-chat-item15 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item15:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox16 {
  margin-right: 5px;
}
.livechat-image19 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont15 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text57 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text58 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont15 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text59 {
  font-size: 10px;
}
.livechat-chat-item16 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item16:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox17 {
  margin-right: 5px;
}
.livechat-image20 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont16 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text60 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text61 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont16 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text62 {
  font-size: 10px;
}
.livechat-chat-item17 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item17:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox18 {
  margin-right: 5px;
}
.livechat-image21 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont17 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text63 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text64 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont17 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text65 {
  font-size: 10px;
}
.livechat-chat-item18 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item18:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox19 {
  margin-right: 5px;
}
.livechat-image22 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-msg-cont18 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text66 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}
.livechat-text67 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont18 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text68 {
  font-size: 10px;
}
.livechat-chat-item19 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 50px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: #c5c5c5;
  border-style: solid;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-chat-item19:hover {
  background-color: var(--dl-color-gray-white);
}
.livechat-checkbox20 {
  margin-right: 5px;
}
.livechat-image23 {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 1px;
  margin-bottom: 2px;
}
.livechat-msg-cont19 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text69 {
  color: rgb(88, 91, 91);
  width: auto;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.livechat-text70 {
  color: rgb(88, 91, 91);
  width: 100%;
  overflow: hidden;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.livechat-time-cont19 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.livechat-text71 {
  font-size: 10px;
}
.livechat-conversation-section {
  flex: 1 0 auto;
  width: 500px;
  height: 100%;
  display: flex;
  z-index: 1;
  box-shadow: -10px 0 10px -10px #d4d4d4, 10px 0 10px -10px #d4d4d4;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: white;
}
.livechat-conversation-header1 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  padding: 0px;
  align-items: center;
  padding-top: 10px;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.livechat-contact-source {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.livechat-text72 {
  color: rgb(88, 91, 91);
  width: auto;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.livechat-bot-name {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-image24 {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 1px;
  margin-bottom: 2px;
}
.livechat-text73 {
  color: rgb(88, 91, 91);
  width: auto;
  overflow: hidden;
  font-size: 14px;
  margin-right: 5px;
  text-overflow: ellipsis;
}
.livechat-action-container {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 10px;
  justify-content: flex-end;
}
.livechat-icon14 {
  fill: var(--dl-color-success-500);
  width: 16px;
  cursor: pointer;
  height: 16px;
  margin-right: 8px;
}
.livechat-image25 {
  width: 16px;
  cursor: pointer;
  height: 16px;
  object-fit: cover;
}
.livechat-conversation-subheader {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.livechat-assignee {
  flex: 0 0 auto;
  width: 140px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 0px;
  justify-content: flex-start;
}
.livechat-thq-dropdown {
  width: auto;
  cursor: pointer;
  height: 100%;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.livechat-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.livechat-text74 {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: 4px;
  vertical-align: middle;
}
.livechat-dropdown-arrow {
  transition: 0.3s;
}
.livechat-icon16 {
  width: 18px;
  height: 18px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}
.livechat-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.livechat-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.livechat-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.livechat-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.livechat-text75 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.livechat-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.livechat-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.livechat-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.livechat-text76 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.livechat-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.livechat-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.livechat-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.livechat-text77 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.livechat-textinput1 {
  width: 150px;
  height: 24px;
  font-size: 12px;
  border-color: #c5c5c5;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  outline-color: var(--dl-color-primary-300);
}
.livechat-conversation-body {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  align-items: flex-start;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 15px;
}
.livechat-message-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-height: calc(100% - 90px);
  overflow-y: scroll;
  align-items: center;
  margin-bottom: 5px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.livechat-msg-body {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  flex-direction: column;
}
.livechat-msg-sender {
  flex: 0 0 auto;
  width: 70%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.livechat-text78 {
  color: rgb(88, 91, 91);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.livechat-image26 {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-left: 5px;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-message {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  padding: 10px;
  max-width: 70%;
  align-items: flex-end;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-primary-300);
}
.livechat-text79 {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;
  width: 100%;
}
.livechat-text80 {
  color: white;
  font-size: 10px;
  margin-top: 3px;
}
.livechat-msg-body1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-direction: column;
}
.livechat-msg-sender1 {
  flex: 0 0 auto;
  width: 70%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.livechat-image27 {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-left: 0px;
  margin-right: 5px;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-text81 {
  color: rgb(88, 91, 91);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.livechat-message1 {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  padding: 10px;
  max-width: 70%;
  align-items: flex-end;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  padding-bottom: 10px;
  background-color: var(--dl-color-gray-900);
}
.livechat-text82 {
  color: rgb(88, 91, 91);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;
  width: 100%;
}
.livechat-text83 {
  color: rgb(88, 91, 91);
  font-size: 10px;
  margin-top: 3px;
}
.livechat-msg-privatenote {
  flex: 0 0 auto;
  width: 70%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: #ebd5ce;
}
.livechat-privatenote-header {
  color: rgb(88, 91, 91);
  font-size: 10px;
  margin-bottom: 3px;
}
.livechat-privatenote-body {
  color: #585B5B;
  font-size: 12px;
}
.livechat-textarea-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;

}
.livechat-text-area {
  flex: 0 0 auto;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: flex-start;
  border-radius: Radius8;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.livechat-textinput2 {
  width: 100%;
  height: 100%;
  font-size: 12px;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  outline-style: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.livechat-action-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 42px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  justify-content: space-between;
}
.livechat-container10 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.livechat-icon18 {
  fill: var(--dl-color-gray-500);
  width: 18px;
  cursor: pointer;
  height: 18px;
}
.livechat-icon20 {
  fill: var(--dl-color-gray-500);
  width: 16px;
  cursor: pointer;
  height: 16px;
  margin-left: 10px;
}
.livechat-container11 {
  flex: 0 0 auto;
  width: 50px;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--dl-color-primary-300);
}
.livechat-container11:active {
  background-color: var(--dl-color-primary-700);
}
.livechat-icon22 {
  fill: white;
  width: 16px;
  height: 16px;
}
.livechat-contact-detail {
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #F0F4F8;
}
.livechat-chat-header1 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.livechat-text84 {
  color: rgb(88, 91, 91);
  font-size: 15px;
  margin-left: 5px;
}
.livechat-icon24 {
  fill: #585B5B;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.livechat-chat-body {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
}
.livechat-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  border-color: #c5c5c5;
  border-width: 0px;
  padding-bottom: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.livechat-image28 {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.livechat-container13 {
  flex: 1;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
  flex-direction: column;
  justify-content: center;
}
.livechat-text85 {
  color: #585B5B;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.livechat-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  border-color: #c5c5c5;
  border-width: 1px;
  flex-direction: column;
  padding-bottom: 10px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.livechat-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.livechat-text86 {
  color: #585B5B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.livechat-text87 {
  color: rgb(88, 91, 91);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding-left: 5px;
}
.livechat-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.livechat-text88 {
  color: #585B5B;
  font-size: 14px;
}
.livechat-text89 {
  color: rgb(88, 91, 91);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding-left: 5px;
}
.view_contact{
  color: #0074F0;
  cursor: pointer;
  font-size: 14px
}
@media(max-width: 991px) {
  .livechat-assignee-section {
    display: none;
  }
  .livechat-conversation-section {
    width: 450px;
  }
  .livechat-chat-body {
    width: auto;
  }
}
@media(max-width: 767px) {
  .livechat-conversation-section {
    width: 380px;
  }
  .livechat-contact-detail {
    display: none;
  }
}
@media(max-width: 479px) {
  .livechat-logotext {
    font-size: 14px;
  }
  .livechat-image {
    width: 30px;
    height: 30px;
  }
  .livechat-chat-section {
    display: none;
  }
  .livechat-conversation-section {
    width: 100%;
  }
  
}


.livechat-attachmentcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.livechat-attachment {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 5px;
  background-color: rgb(241,240,241);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.livechat-text81 {
  overflow: hidden;
  font-size: 14px;
  max-width: 300px;
  font-style: normal;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.livechat-close-icon {
  fill: rgb(88, 91, 91);
  width: 14px;
  cursor: pointer;
  height: 14px;
  transition: 0.3s;
  margin-left: 10px;
}
.livechat-close-icon:hover {
  fill: #888c8c;
}
.livechat-contact-info {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.livechat-viewcontact {
  color: var(--dl-color-primary-300);
  width: auto;
  cursor: pointer;
  font-size: 14px;
}
.livechat-marker-container {
  flex: 0 0 auto;
  width: 30px;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 12px;
  border-radius: 5px;
  justify-content: center;
  background-color: #E4E8F1;
}

.livechat-fullname-unread {
  color: black;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 7px;
  text-overflow: ellipsis;
}


.livechat-message-unread {
  color: black;
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}