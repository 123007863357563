.plugin-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #D9D9D9;
  }
  .plugin-initiate {
    flex: 0 0 auto;
    width: 85%;
    height: 600px;
    display: flex;
    padding: 20px;
    overflow-y: scroll;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: white;
  }
  .plugin-title {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 10px;
    border-color: grey;
    border-width: 1px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    justify-content: space-between;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .plugin-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .plugin-button {
    color: white;
    cursor: pointer;
    border-width: 0px;
    background-color: var(--dl-color-primary-300);
  }
  .plugin-plugindetail {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    border-color: #DADBDD;
    border-width: 1px;
    margin-bottom: 30px;
    flex-direction: column;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .plugin-param-label {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .plugin-pluginname {
    flex: 0 0 auto;
    width: 30%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: row;
    justify-content: flex-start;
  }
  .plugin-text01 {
    color: rgb(31, 39, 48);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .plugin-text02 {
    color: rgb(31, 39, 48);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .plugin-plugindescription {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    flex-direction: row;
  }
  .plugin-text03 {
    color: rgb(31, 39, 48);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .plugin-text04 {
    color: rgb(31, 39, 48);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .plugin-param-body {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .plugin-name {
    flex: 0 0 auto;
    width: 30%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .plugin-textinput {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .plugin-description {
    flex: 1;
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    flex-direction: column;
  }
  .plugin-textinput1 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .plugin-group {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .plugin-param-label1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .plugin-requiredlabel {
    flex: 0 0 auto;
    width: 5%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .plugin-text05 {
    color: rgb(31, 39, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .plugin-namelabel {
    flex: 0 0 auto;
    width: 25%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 15px;
  }
  .plugin-text06 {
    color: rgb(31, 39, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .plugin-typelabel {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .plugin-text07 {
    color: rgb(31, 39, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .plugin-descriptionlabel {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    flex-direction: column;
  }
  .plugin-text08 {
    color: rgb(31, 39, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .plugin-param-body1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .plugin-required {
    flex: 0 0 auto;
    width: 5%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: center;
  }
  .plugin-checkbox {
    width: 16px;
    height: 16px;
  }
  .plugin-name1 {
    flex: 0 0 auto;
    width: 25%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .plugin-textinput2 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .plugin-type {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .plugin-textinput3 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .plugin-description1 {
    flex: 1;
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    flex-direction: column;
  }
  .plugin-textinput4 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .plugin-icon {
    fill: #DADBDD;
    width: 20px;
    cursor: pointer;
    height: 20px;
    transition: 0.3s;
    margin-left: 10px;
  }
  .plugin-icon:hover {
    fill: var(--dl-color-danger-700);
  }
  .plugin-button1 {
    color: var(--dl-color-primary-300);
    cursor: pointer;
    align-self: center;
    border-color: #DADBDD;
  }
  .plugin-return {
    flex: 0 0 auto;
    width: 85%;
    height: 600px;
    display: flex;
    padding: 20px;
    overflow-y: scroll;
    align-items: flex-start;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: white;
  }
  .plugin-title1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 10px;
    border-color: grey;
    border-width: 1px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    justify-content: space-between;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .plugin-text09 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .plugin-button2 {
    color: white;
    cursor: pointer;
    border-width: 0px;
    background-color: var(--dl-color-primary-300);
  }
  .plugin-group1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .plugin-param-label2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .plugin-namelabel1 {
    flex: 0 0 auto;
    width: 30%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .plugin-text10 {
    color: rgb(31, 39, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .plugin-typelabel1 {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .plugin-text11 {
    color: rgb(31, 39, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .plugin-descriptionlabel1 {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    flex-direction: row;
  }
  .plugin-text12 {
    color: rgb(31, 39, 48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .plugin-param-body2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .plugin-name2 {
    flex: 0 0 auto;
    width: 30%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .plugin-textinput5 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .plugin-type1 {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .plugin-textinput6 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .plugin-description2 {
    flex: 1;
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    flex-direction: column;
  }
  .plugin-textinput7 {
    width: 100%;
    border-color: #DADBDD;
    outline-color: var(--dl-color-primary-500);
  }
  .plugin-icon3 {
    fill: #DADBDD;
    width: 20px;
    cursor: pointer;
    height: 20px;
    transition: 0.3s;
    margin-left: 10px;
  }
  .plugin-icon3:hover {
    fill: var(--dl-color-danger-700);
  }
  .plugin-button3 {
    color: var(--dl-color-primary-300);
    cursor: pointer;
    align-self: center;
    border-color: #DADBDD;
  }
  