.contacts-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  flex-direction: column;
  background-color: #f7f7f7;
}
.contacts-container01 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.contacts-container02 {
  flex: 0 0 auto;
  width: 15%;
  display: flex;
  align-items: flex-start;
}
.contacts-contacts {
  flex: 0 0 auto;
  width: calc(100% - 150px);
  height: 100vh;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.contacts-header {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.contacts-container03 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}
.contacts-container04 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-container05 {
  flex: 0 0 auto;
  width: 300px;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: flex-end;
}
.contacts-button {
  color: white;
  cursor: pointer;
  height: 75%;
  display: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-300);
}
.contacts-body {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  margin-top: 30px;
  align-items: flex-start;
  padding-left: 30px;
  margin-bottom: 20px;
  padding-right: 30px;
  flex-direction: column;
}
.contacts-contactnavbar {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contacts-container06 {
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
}
.contacts-textinput {
  width: 100%;
  border-color: var(--dl-color-primary-300);
  outline-color: var(--dl-color-primary-300);
}
.contacts-container07 {
  flex: 0 0 auto;
  width: 250px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contacts-text01 {
  font-size: 14px;
}
.contacts-button1 {
  cursor: pointer;
  transition: 0.3s;
  margin-left: 20px;
  border-color: #c5c5c5;
}
.contacts-button1:hover {
  background-color: #eaeaea;
}
.contacts-contactitemcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.contacts-contactitem {
  top: 0;
  flex: 0 0 auto;
  width: 100%;
  height: 55px;
  display: flex;
  position: sticky;
  align-items: flex-start;
  background-color: var(--dl-color-gray-white);
}
.contacts-container08 {
  flex: 0 0 auto;
  width: 3%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-checkbox {
  width: 60%;
  height: 60%;
}
.contacts-container09 {
  flex: 0 0 auto;
  width: 8%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text02 {
  font-style: normal;
  font-weight: 500;
}
.contacts-container10 {
  flex: 0 0 auto;
  width: 37%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text03 {
  font-style: normal;
  font-weight: 500;
}
.contacts-container11 {
  flex: 0 0 auto;
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text04 {
  font-style: normal;
  font-weight: 500;
}
.contacts-container12 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text05 {
  font-style: normal;
  font-weight: 500;
}
.contacts-container13 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text06 {
  font-style: normal;
  font-weight: 500;
}
.contacts-container14 {
  flex: 0 0 auto;
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text07 {
  font-style: normal;
  font-weight: 500;
}
.contacts-contactitem1 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 70px;
  display: none;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-bottom: 0px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.contacts-text08 {
  color: var(--dl-color-gray-500);
}
.contacts-contactitem2 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 70px;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-bottom: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.contacts-container15 {
  flex: 0 0 auto;
  width: 3%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-checkbox1 {
  width: 60%;
  height: 60%;
}
.contacts-container16 {
  flex: 0 0 auto;
  width: 8%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contacts-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.contacts-container17 {
  flex: 0 0 auto;
  width: 37%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text09 {
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  text-overflow: ellipsis;
}
.contacts-container18 {
  flex: 0 0 auto;
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text10 {
  font-style: normal;
  font-weight: 400;
}
.contacts-container19 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text11 {
  font-style: normal;
  font-weight: 400;
}
.contacts-container20 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text12 {
  font-style: normal;
  font-weight: 400;
}
.contacts-container21 {
  flex: 0 0 auto;
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
}
.contacts-text13 {
  font-style: normal;
  font-weight: 500;
}
.contacts-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  align-items: flex-start;
  padding-top: 30px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.contacts-newbotwidget {
  flex: 0 0 auto;
  width: 450px;
  height: 300px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: white;
}
.contacts-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  border-color: #c5c5c5;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.contacts-container24 {
  flex: 0 0 auto;
  width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contacts-text14 {
  margin-left: 50px;
}
.contacts-container25 {
  flex: 0 0 auto;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contacts-icon {
  width: 60%;
  cursor: pointer;
  height: 60%;
}
.contacts-container26 {
  flex: 0 0 auto;
  width: 80%;
  height: 260px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 36px;
  flex-direction: column;
}
.contacts-textinput1 {
  width: 100%;
  height: 35px;
  border-color: #c5c5c5;
  outline-color: var(--dl-color-primary-300);
}
.contacts-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.contacts-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-dropdown-toggle:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.contacts-text15 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.contacts-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-dropdown-toggle01:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.contacts-text16 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.contacts-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-dropdown-toggle02:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.contacts-text17 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.contacts-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-dropdown-toggle03:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.contacts-text18 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.contacts-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-dropdown-toggle04:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.contacts-text19 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.contacts-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.contacts-text20 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.contacts-container27 {
  flex: 0 0 auto;
  width: 100%;
  height: 35px;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.contacts-thq-dropdown1 {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-text21 {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: 4px;
  vertical-align: middle;
}
.contacts-dropdown-arrow {
  transition: 0.3s;
}
.contacts-icon2 {
  width: 18px;
  height: 18px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}
.contacts-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.contacts-dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-dropdown-toggle07:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.contacts-text22 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.contacts-dropdown7 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.contacts-text23 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.contacts-dropdown8 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.contacts-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.contacts-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.contacts-text24 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.contacts-container28 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: center;
  margin-bottom: 38px;
  justify-content: flex-end;
}
.contacts-button2 {
  cursor: pointer;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.contacts-button3 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  height: 30px;
  margin-left: 10px;
  padding-top: 5px;
  border-width: 0px;
  padding-bottom: 5px;
  background-color: var(--dl-color-primary-300);
}
@media(max-width: 991px) {
  .contacts-container02 {
    width: 15%;
  }
  .contacts-contacts {
    width: calc(100% - 150px);
  }
}
@media(max-width: 767px) {
  .contacts-container01 {
    min-width: auto;
  }
  .contacts-container02 {
    width: 150px;
    flex-direction: column;
  }
  .contacts-contacts {
    width: calc(100% - 150px);
  }
  .contacts-container09 {
    margin-right: 5px;
    padding-right: 0px;
  }
  .contacts-container11 {
    width: 15%;
  }
  .contacts-container12 {
    width: 20%;
  }
  .contacts-container13 {
    display: none;
  }
  .contacts-container16 {
    margin-right: 5px;
  }
  .contacts-container18 {
    width: 15%;
  }
  .contacts-container19 {
    width: 20%;
  }
  .contacts-container20 {
    display: none;
  }
}
@media(max-width: 479px) {
  .contacts-container02 {
    width: 120px;
  }
  .contacts-contacts {
    width: calc(100% - 120px);
  }
  .contacts-header {
    width: 100%;
  }
  .contacts-text {
    font-size: 13px;
  }
  .contacts-body {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .contacts-container06 {
    flex: 1;
    width: auto;
    padding-right: 20px;
  }
  .contacts-container07 {
    flex: 1;
    width: auto;
  }
  .contacts-text01 {
    font-size: 12px;
  }
  .contacts-button1 {
    flex: 1;
    font-size: 12px;
    margin-left: 5px;
  }
  .contacts-container08 {
    width: 20px;
  }
  .contacts-container09 {
    width: 40px;
  }
  .contacts-text02 {
    font-size: 12px;
  }
  .contacts-container10 {
    flex: 1;
  }
  .contacts-text03 {
    font-size: 12px;
  }
  .contacts-container11 {
    flex: 1;
  }
  .contacts-text04 {
    font-size: 12px;
  }
  .contacts-container12 {
    display: none;
  }
  .contacts-container14 {
    display: none;
  }
  .contacts-container15 {
    flex: 0 0 auto;
    width: 20px;
  }
  .contacts-container16 {
    flex: 0 0 auto;
    width: 40px;
  }
  .contacts-image {
    width: 100%;
    height: auto;
  }
  .contacts-container17 {
    flex: 1;
  }
  .contacts-text09 {
    font-size: 10px;
  }
  .contacts-container18 {
    flex: 1;
  }
  .contacts-text10 {
    font-size: 10px;
  }
  .contacts-container19 {
    display: none;
  }
  .contacts-container21 {
    display: none;
  }
}

.profile-container {
  width: 100%;
  display: flex;
  overflow-y: scroll;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.profile-contactdetail {
  flex: 0 0 auto;
  width: 1000px;
  height: 550px;
  display: flex;
  align-self: center;
  align-items: flex-end;
  padding-top: 30px;
  border-color: #DADBDD;
  border-width: 1px;
  padding-left: 60px;
  border-radius: 10px;
  padding-right: 60px;
  flex-direction: column;
  padding-bottom: 30px;
  overflow-y: scroll;
  background-image: linear-gradient(90deg, rgba(203, 160, 218, 1) 0%, rgba(220, 231, 253, 1) 100%);;
}
.profile-closebutton-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: 20px;
  justify-content: flex-end;
}
.profile-icon {
  fill: #83878C;
  width: 26px;
  cursor: pointer;
  height: 26px;
}
.profile-contactdetail-conteiner {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.profile-contactdetail-group {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.profile-contactdetail-left {
  flex: 0 0 auto;
  width: 40%;
  height: 540px;
  display: flex;
  padding: 20px;
  position: relative;
  align-items: flex-start;
  border-color: #B4B7BA;
  border-width: 1px;
  margin-right: 30px;
  border-radius: 10px;
  flex-direction: column;
  background-color: white;
}
.profile-icon-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
}
.profile-more-icon {
  flex: 0 0 auto;
  width: 40px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: #DADBDD;
  border-width: 1px;
  border-radius: 5px;
  justify-content: center;
}
.profile-icon02 {
  fill: #51575E;
  width: 24px;
  height: 24px;
}
.profile-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 140px;
  height: 140px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.profile-subscribtion {
  top: 140px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 134px;
  bottom: 0px;
  height: 30px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  padding-left: 13px;
  border-radius: 15px;
  padding-right: 13px;
  justify-content: space-between;
  background-color: #705CF6;
}
.profile-icon04 {
  fill: white;
  width: 16px;
  height: 16px;
}
.profile-text {
  color: white;
  font-size: 14px;
}
.profile-text01 {
  color: #1F2730;
  font-size: 20px;
  align-self: center;
  margin-top: 140px;
  text-align: center;
}
.profile-detail {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  margin-left: 30px;
  flex-direction: column;
}
.profile-detail-item {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
}
.profile-icon06 {
  fill: #83878C;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.profile-text03 {
  color: #83878c;
}
.profile-text05 {
  color: #705cf6;
  font-style: normal;
  font-weight: 500;
}
.profile-detail-item1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
}
.profile-icon08 {
  fill: #83878C;
  width: 24px;
  height: 20px;
  margin-right: 10px;
}
.profile-text07 {
  color: #83878c;
}
.profile-text09 {
  color: #705cf6;
  font-style: normal;
  font-weight: 500;
}
.profile-detail-item2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
}
.profile-icon10 {
  fill: #83878C;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.profile-text11 {
  color: #83878c;
}
.profile-text13 {
  color: #705cf6;
  font-style: normal;
  font-weight: 500;
}
.profile-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.profile-detail-item3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
}
.profile-image1 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 10px;
}
.profile-text15 {
  color: #83878c;
}
.profile-text17 {
  color: #705cf6;
  font-style: normal;
  font-weight: 500;
}
.profile-button {
  color: white;
  width: 200px;
  cursor: pointer;
  align-self: center;
  margin-top: 32px;
  border-width: 0px;
  border-radius: 5px;
  background-color: rgb(112, 92, 246);
}
.profile-contactdetail-right {
  flex: 1;
  width: auto;
  height: 540px;
  display: flex;
  align-items: flex-start;
  border-color: #B4B7BA;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  background-color: white;
  overflow-y: scroll;
  padding-bottom: 20px;
}
.profile-right-item {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: #B4B7BA;
  border-width: 1px;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.profile-text18 {
  color: #1F2730;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.profile-button1 {
  color: rgb(112, 92, 246);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  border-width: 0px;
}
.profile-right-item1 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: #B4B7BA;
  border-width: 1px;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.profile-text19 {
  color: rgb(31, 39, 48);
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.profile-button2 {
  color: rgb(112, 92, 246);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  border-width: 0px;
}
.profile-right-item2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  border-color: #B4B7BA;
  border-width: 1px;
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: column;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.profile-container2 {
  flex: 0 0 auto;
  width: 60%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.profile-text20 {
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.profile-button3 {
  color: rgb(112, 92, 246);
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  border-color: #705CF6;
  border-width: 1px;
  border-radius: 15px;
  margin-bottom: 5px;
  background-color: rgb(237, 235, 253);
}
.profile-text22 {
  color: #83878c;
  font-style: normal;
  font-weight: 600;
}
.profile-button4 {
  color: rgb(112, 92, 246);
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  border-color: #705CF6;
  border-width: 1px;
  border-radius: 15px;
  background-color: rgb(237, 235, 253);
  margin-bottom: 20px;
}
.profile-text25 {
  color: #83878c;
  font-style: normal;
  font-weight: 600;
}
.profile-right-item3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: column;
  justify-content: flex-start;
}
.profile-head {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-text27 {
  font-size: 18px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.profile-button5 {
  color: rgb(112, 92, 246);
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  border-width: 0px;
}
.profile-variables-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.profile-variable {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-self: center;
  margin-top: 4%;
  align-items: center;
  border-color: #705CF6;
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  justify-content: center;
}
.profile-text28 {
  width: 90%;
  height: 80%;
  font-size: 16px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  padding-top: 1.4%;
  padding-left: 2%;
}
.profile-text30 {
  color: #705cf6;
}
.profile-variable1 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-self: center;
  margin-top: 4%;
  align-items: center;
  border-color: #705CF6;
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  justify-content: center;
}
.profile-text31 {
  width: 90%;
  height: 80%;
  font-size: 16px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  padding-top: 1.4%;
  padding-left: 2%;
}
.profile-text33 {
  color: #705cf6;
}
.profile-variable2 {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  display: flex;
  align-self: center;
  margin-top: 4%;
  align-items: center;
  border-color: #705CF6;
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  justify-content: center;
}
.profile-text34 {
  width: 90%;
  height: 80%;
  font-size: 16px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  padding-top: 1.4%;
  padding-left: 2%;
}
.profile-text36 {
  color: #705cf6;
}
.profile-button6 {
  color: #705CF6;
  font-size: 17px;
  font-style: normal;
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
  margin-left: 40px;
  border-width: 0px;
  border-radius: 15px;
  cursor: pointer;
}
.profile-contactdetail-body {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  overflow-y: scroll;
}