input:checked ~ .dot {
  transform: translateX(100%);
  background-color: white;
}

.chatbot-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  flex-direction: column;
  background-color: #f7f7f7;
}
.chatbot-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.chatbot-container02 {
  flex: 0 0 auto;
  width: calc(100% - 150px);
  height: 100vh;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.chatbot-header {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.chatbot-container03 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}
.chatbot-container04 {
  flex: 0 0 auto;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-container05 {
  flex: 0 0 auto;
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.chatbot-button {
  color: white;
  cursor: pointer;
  height: auto;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  background-color: var(--dl-color-primary-300);
}
.chatbot-body {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  margin-top: 30px;
  max-height: calc(100vh - 100px);
  overflow-y: hidden;
  align-items: flex-start;
  padding-left: 30px;
  margin-bottom: 0px;
  padding-right: 30px;
  flex-direction: column;
}
.chatbot-subheading {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbot-container07 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.chatbot-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: var(--dl-radius-radius-round);
}
.chatbot-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbot-container09 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.chatbot-container10 {
  flex: 0 0 auto;
  width: 20%;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
}
.chatbot-container10:hover {
  border-color: var(--dl-color-success-500);
  border-width: 5px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.flow_trigger_Setting {
  border-color: var(--dl-color-success-500);
  border-bottom-width: 5px;
}

.chatbot-icon {
  fill: rgb(88, 91, 91);
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.chatbot-text05 {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 600;
}
.chatbot-container11 {
  flex: 0 0 auto;
  width: 20%;
  cursor: pointer;
  height: 100%;
  display: none;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
}
.chatbot-container11:hover {
  border-color: var(--dl-color-success-500);
  border-width: 5px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbot-text06 {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 600;
}
.chatbot-container12 {
  flex: 0 0 auto;
  width: 20%;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
}
.chatbot-container12:hover {
  border-color: var(--dl-color-success-500);
  border-width: 5px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.chatbot-icon02 {
  fill: rgb(88, 91, 91);
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.chatbot-text07 {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 600;
}
.chatbot-container13 {
  flex: 0 0 auto;
  width: 20%;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
}
.chatbot-container13:hover {
  border-color: var(--dl-color-success-500);
  border-width: 5px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbot-icon04 {
  fill: rgb(88, 91, 91);
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.chatbot-text08 {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 600;
}
.chatbot-container14 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.chatbot-text09 {
  color: rgb(88, 91, 91);
  font-style: normal;
  font-weight: 600;
  margin-right: 5px;
}
.chatbot-icon06 {
  fill: rgb(88, 91, 91);
  width: 24px;
  height: 24px;
}
.chatbot-body1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  margin-top: 30px;
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
  align-items: flex-start;
  padding-left: 30px;
  margin-bottom: 40px;
  padding-right: 30px;
  flex-direction: column;
}
.chatbot-flowcontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  background-color: transparent;
}
.chatbot-flowheader {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.chatbot-container15 {
  flex: 0 0 auto;
  width: 250px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.chatbot-text10 {
  font-size: 14px;
}
.chatbot-button1 {
  color: white;
  cursor: pointer;
  height: auto;
  margin-left: 20px;
  border-color: #c5c5c5;
  background-color: var(--dl-color-danger-500);
}
.chatbot-flowitem {
  top: 0;
  flex: 0 0 auto;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
}
.chatbot-container16 {
  flex: 0 0 auto;
  width: 3%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-checkbox {
  width: 60%;
  height: 60%;
}
.chatbot-container17 {
  flex: 0 0 auto;
  width: 37%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-text11 {
  font-style: normal;
  font-weight: 500;
}
.chatbot-container18 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-text12 {
  font-style: normal;
  font-weight: 500;
}
.chatbot-emptyflow {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 70px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-bottom: 0px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.chatbot-text13 {
  color: var(--dl-color-gray-500);
}
.chatbot-flowitem1 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 70px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  background-color: white;
  margin-bottom: 20px;
}

.chatbot-trashitem1 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  background-color: white;
  margin-bottom: 20px;
}

.chatbot-container19 {
  flex: 0 0 auto;
  width: 3%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-checkbox1 {
  width: 60%;
  height: 60%;
}
.chatbot-container20 {
  flex: 0 0 auto;
  width: 37%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-text14 {
  font-style: normal;
  font-weight: 400;
}
.chatbot-container21 {
  flex: 0 0 auto;
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-text15 {
  font-style: normal;
  font-weight: 400;
}
.chatbot-container22 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 5%;
  cursor: pointer;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.chatbot-icon08 {
  width: 24px;
  height: 24px;
}
.chatbot-triggercontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  background-color: transparent;
}
.chatbot-triggeritem {
  top: 0;
  flex: 0 0 auto;
  width: 100%;
  height: 55px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
}
.chatbot-container23 {
  flex: 0 0 auto;
  width: 8%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-text16 {
  font-style: normal;
  font-weight: 500;
}
.chatbot-container24 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-text17 {
  font-style: normal;
  font-weight: 500;
}
.chatbot-container25 {
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
}
.chatbot-text18 {
  font-style: normal;
  font-weight: 500;
}
.chatbot-emptytrigger {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 70px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-bottom: 0px;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.chatbot-text19 {
  color: var(--dl-color-gray-500);
}
.chatbot-triggeritem1 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  padding: 20px;
  position: relative;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 0px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: white;
  margin-bottom: 20px;
}
.chatbot-container26 {
  flex: 0 0 auto;
  width: 8%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: "pointer"
}
.chatbot-icon10 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.chatbot-icon12 {
  fill: var(--dl-color-success-500);
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.chatbot-container27 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
}
.chatbot-text20 {
  font-style: normal;
  font-weight: 400;
}
.chatbot-container28 {
  flex: 0 0 auto;
  width: 30%;
  height: auto;
  display: flex;
  align-items: center;
}
.chatbot-text21 {
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.chatbot-container29 {
  flex: 0 0 auto;
  width: 12%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.chatbot-icon14 {
  fill: #585B5B;
  width: 24px;
  cursor: pointer;
  height: 24px;
}
.chatbot-icon16 {
  fill: #585B5B;
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-left: 10px;
}
.chatbot-settingscontainer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  background-color: transparent;
}
.chatbot-header1 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 30px;
  background-color: white;
}
.chatbot-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbot-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbot-text22 {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: 4px;
  vertical-align: middle;
}
.chatbot-dropdown-arrow {
  transition: 0.3s;
}
.chatbot-icon18 {
  width: 18px;
  height: 18px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}
.chatbot-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.chatbot-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbot-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbot-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.chatbot-text23 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.chatbot-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbot-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbot-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.chatbot-text24 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.chatbot-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbot-wapi {
  width: 100%;
  height: auto;
  padding: 10px;
  flex-grow: 1;
  align-items: center;
  margin-left: 0px;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  background-color: var(--dl-color-gray-white);
}
.chatbot-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  margin-bottom: 20px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbot-text25 {
  color: rgb(88, 91, 91);
}
.chatbot-container31 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container32 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 5px;
  flex-direction: row;
  justify-content: space-between;
}
.chatbot-textinput {
  width: 100%;
  border-width: 0px;
  border-radius: 0px;
  outline-color: var(--dl-color-primary-300);
}
.chatbot-container33 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container34 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 5px;
  justify-content: space-between;
}
.chatbot-textinput1 {
  width: 100%;
  border-width: 0px;
  border-radius: 0px;
  outline-color: var(--dl-color-primary-300);
}
.chatbot-container35 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container36 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: flex-end;
}
.chatbot-button2 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  transition: 0.3s;
  border-width: 0px;
  background-color: var(--dl-color-primary-300);
}
.chatbot-button2:hover {
  background-color: var(--dl-color-primary-300);
}
.chatbot-maytapi {
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px;
  flex-grow: 1;
  align-items: center;
  margin-left: 0px;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  background-color: var(--dl-color-gray-white);
}
.chatbot-container37 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  margin-bottom: 20px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbot-text28 {
  color: rgb(88, 91, 91);
}
.chatbot-container38 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container39 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 5px;
  flex-direction: row;
  justify-content: space-between;
}
.chatbot-textinput2 {
  width: 100%;
  border-width: 0px;
  border-radius: 0px;
  outline-color: var(--dl-color-primary-300);
}
.chatbot-container40 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container41 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 5px;
  justify-content: space-between;
}
.chatbot-textinput3 {
  width: 100%;
  border-width: 0px;
  border-radius: 0px;
  outline-color: var(--dl-color-primary-300);
}
.chatbot-container42 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container43 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 5px;
  justify-content: space-between;
}
.chatbot-textinput4 {
  width: 100%;
  border-width: 0px;
  border-radius: 0px;
  outline-color: var(--dl-color-primary-300);
}
.chatbot-container44 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container45 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: flex-end;
}
.chatbot-button3 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  transition: 0.3s;
  border-width: 0px;
  background-color: var(--dl-color-primary-300);
}
.chatbot-button3:hover {
  background-color: var(--dl-color-primary-300);
}
.chatbot-webhook {
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px;
  flex-grow: 1;
  align-items: center;
  margin-left: 0px;
  padding-top: 20px;
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  background-color: var(--dl-color-gray-white);
}
.chatbot-container46 {
  flex: 0 0 auto;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-width: 1px;
  margin-bottom: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbot-text32 {
  color: rgb(88, 91, 91);
}
.chatbot-container47 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container48 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 0px;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 5px;
  justify-content: space-between;
}
.chatbot-textinput5 {
  width: 100%;
  border-width: 0px;
  border-radius: 0px;
  outline-color: var(--dl-color-primary-300);
}
.chatbot-container49 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.chatbot-container50 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: flex-end;
}
.chatbot-button4 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  transition: 0.3s;
  border-width: 0px;
  background-color: var(--dl-color-primary-300);
}
.chatbot-button4:hover {
  background-color: var(--dl-color-primary-300);
}
.chatbot-container51 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.chatbot-newbotwidget {
  flex: 0 0 auto;
  width: 508px;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: white;
}
.chatbot-container52 {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  border-color: #c5c5c5;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.chatbot-container53 {
  flex: 0 0 auto;
  width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbot-text33 {
  margin-left: 50px;
}
.chatbot-container54 {
  flex: 0 0 auto;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbot-icon20 {
  width: 60%;
  cursor: pointer;
  height: 60%;
}
.chatbot-container55 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
}
.chatbot-textinput6 {
  width: 100%;
  height: 35px;
  border-color: #c5c5c5;
  outline-color: var(--dl-color-primary-300);
}
.chatbot-container56 {
  flex: 0 0 auto;
  width: 80%;
  height: auto;
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  border-color: #c5c5c5;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
}
.chatbot-container57 {
  flex: 0 0 auto;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}
.chatbot-thq-dropdown1 {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbot-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.chatbot-text34 {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: 4px;
  vertical-align: middle;
}
.chatbot-dropdown-arrow1 {
  transition: 0.3s;
}
.chatbot-icon22 {
  width: 18px;
  height: 18px;
  margin-top: auto;
  transition: 0.3s;
  margin-bottom: auto;
}
.chatbot-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.chatbot-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbot-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbot-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.chatbot-text35 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.chatbot-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbot-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbot-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.chatbot-text36 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.chatbot-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.chatbot-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.chatbot-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: var(--dl-color-primary-300);
}
.chatbot-text37 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.chatbot-textinput7 {
  width: 100%;
  height: 35px;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 5px;
  border-radius: 0px;
  outline-color: var(--dl-color-primary-300);
  padding-right: 5px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.chatbot-container58 {
  flex: 0 0 auto;
  left: 0px;
  width: 80%;
  bottom: 0px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-end;
}
.chatbot-button5 {
  cursor: pointer;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.chatbot-button6 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  height: 30px;
  margin-left: 10px;
  padding-top: 5px;
  border-width: 0px;
  padding-bottom: 5px;
  background-color: var(--dl-color-primary-300);
}
.sequence-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.sequence-header {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.sequence-text {
  cursor: pointer;
  transition: 0.3s;
  margin-right: 5px;
}
.sequence-text:hover {
  color: var(--dl-color-primary-300);
}
.sequence-text1 {
  margin-right: 5px;
}
.sequence-text2 {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
}


@media(max-width: 991px) {
  .chatbot-container09 {
    width: 80%;
  }
  .chatbot-wapi {
    width: 95%;
    height: auto;
    margin-top: 100px;
  }
  .chatbot-maytapi {
    width: 95%;
    height: auto;
    margin-top: 100px;
  }
  .chatbot-webhook {
    width: 95%;
    height: auto;
    margin-top: 100px;
  }
}