.changepassword-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.changepassword-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.changepassword-text {
  color: var(--dl-color-gray-black);
}
.changepassword-navlink {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-success-300);
}
.changepassword-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}
.changepassword-container3 {
  flex: 0 0 auto;
  width: 500px;
  height: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.changepassword-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.changepassword-textinput {
  width: 100%;
  height: 40px;
  border-color: #c5c5c5;
  margin-bottom: 20px;
  outline-color: var(--dl-color-primary-300);
}
.changepassword-textinput1 {
  width: 100%;
  height: 40px;
  border-color: #c5c5c5;
  margin-bottom: 20px;
  outline-color: var(--dl-color-primary-300);
}
.changepassword-textinput2 {
  width: 100%;
  height: 40px;
  border-color: #c5c5c5;
  margin-bottom: 20px;
  outline-color: var(--dl-color-primary-300);
}
.changepassword-textinput3 {
  width: 100%;
  height: 40px;
  border-color: #c5c5c5;
  outline-color: var(--dl-color-primary-300);
}
.changepassword-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-end;
}

.changepassword-button {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  margin-top: 0px;
  border-width: 0px;
  background-color: var(--dl-color-primary-300);
}

.changepassword-button1 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  margin-top: 0px;
  margin-left: 10px;
  border-width: 0px;
  background-color: var(--dl-color-success-500);
}

@media(max-width: 479px) {
  .changepassword-container3 {
    width: auto;
  }
}
