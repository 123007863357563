.message-node {
  min-height: 100px;
  min-width: 250px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
}

.placeholder {
  margin-bottom: 0;
  font-size: 0.9rem;
  color: gray;
}

p {
  margin-bottom: 0;
}

.message-node-header {
  height: 25px;
  background-color: lightgreen;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-node-header p {
  font-size: 0.8rem;
  font-weight: bold;
  color: green;
  margin-bottom: 0;
}
.message-node-container {
  padding: 8px;
  height: 'auto'
}




.flow-node {
  min-height: 100px;
  min-width: 250px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
}

.flow-node-header {
  height: 25px;
  background-color: rgb(56, 135, 255);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flow-node-header p {
  color: white;
  margin-bottom: 0;
}

.flow-node-container {
  padding: 8px;
}

.action-node {
  min-height: 100px;
  min-width: 250px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
}

.action-node-header {
  height: 25px;
  background-color: purple;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.chatgpt-node-header {
  height: 25px;
  background-color: rgb(0, 128, 60);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-node-header p {
  color: white;
  margin-bottom: 0;
}

.action-node-container {
  padding: 8px;
}

.filter-node {
  min-height: 100px;
  min-width: 250px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
}

.filter-node-header {
  height: 25px;
  background-color: orange;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-node-header p {
  color: white;
  margin-bottom: 0;
}

.filter-node-container {
  padding: 8px;
}

.filter-option {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  min-height: 55px;
  padding: 4px;
}
.filter-option-box {
  margin: 0 !important;
  width: 80% !important;
  padding: 8px !important;
  min-height: 55px;
}
.filter-option p {
  margin-bottom: 0;
  text-align: left;
  background-color: #eee;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: .8rem;
}

.api-node {
  min-height: 100px;
  min-width: 250px;
  max-width: 400px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
}

.api-node-header {
  height: 25px;
  background-color: rgb(134, 0, 0);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.api-node-header p {
  color: white;
  margin-bottom: 0;
}

.api-node-container {
  padding: 8px;
}

.api-node-link {
  padding: 4px 8px;
  padding-bottom: 6px;
  background-color: #eee;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 0;
  display: inline-block;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.api-node-field {
  padding: 2px 8px;
  padding-bottom: 4px;
  background-color: #e5eff3;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 0;
  display: inline-block;
  font-size: 0.8rem;
  margin-right: 8px;
  color: rgb(0, 94, 122);
}

.api-option-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.api-option {
  border: 1px solid #ccc;
  min-width: 80px;
  border-radius: 900px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  height: 30px;
}
.api-option-box {
  margin: 0 !important;
  display: flex !important;
  justify-content: "space-between";
  align-items: "center" !important;
  /* width: 10% !important; */
  padding: 8px !important;
  width: 70px;
}

.norespond-box {
  margin: 0 !important;
  display: flex !important;
  justify-content: "space-between";
  align-items: "center" !important;
  /* width: 10% !important; */
  padding: 8px !important;
  width: auto;
}

.api-option-box p {
  margin-bottom: 0;
  margin-right: 8px;
}

.pause-node {
  min-height: 100px;
  min-width: 250px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
}

.pause-node-header {
  height: 25px;
  background-color: rgb(71, 140, 167);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pause-node-header p {
  color: white;
  margin-bottom: 0;
}

.pause-node-container {
  padding: 8px;
}
.pause-node-container .placeholder {
  color: rgb(37, 37, 37);
  font-weight: 500;
  font-size: 1rem;
  margin-top: 8px;
}
.pause-node-container .subText {
  margin-top: 8px;
  color: gray;
}
.pause-node-container .pause-duration {
  background-color: #eee;
  padding: 3px 6px;
  border-radius: 4px;
}

.random-node {
  min-height: 100px;
  min-width: 250px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
}

.random-node-header {
  height: 25px;
  background-color: rgb(204, 14, 87);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.random-node-header p {
  color: white;
  margin-bottom: 0;
}

.random-node-container {
  padding: 8px;
}

.random-option {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.random-option-box {
  margin: 0 !important;
  width: 75% !important;
  padding: 8px !important;
}

.random-option p {
  margin-bottom: 0;
  font-weight: 500;
  text-align: left;
}
.testingpage-filecontainer {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  border-color: #c5c5c5;
  border-style: dashed;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.testingpage-filecontainer:hover {
  border-color: var(--dl-color-primary-300);
  border-width: 2px;
}
.testingpage-fiileheader {
  flex: 0 0 auto;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border-color: #c5c5c5;
  border-width: 1px;
  padding-left: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.testingpage-text {
  color: purple;
  margin-right: 5px;
}
.testingpage-filebody {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testingpage-text1 {
  color: var(--dl-color-primary-300);
  margin-right: 5px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
}
.bottomaction-button-container {
  position: absolute;
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
}
.bottomaction-btns {
  flex: 0 0 auto;
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
}
.bottomaction-delete {
  flex: 1;
  width: auto;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-bottom: 5px;
  justify-content: center;
  background-color: white;
  border-top-left-radius: 4;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4;
  border-bottom-right-radius: 0;
}
.bottomaction-delete:hover {
  background-color: #dce0e1;
}
.bottomaction-icon {
  fill: var(--dl-color-danger-500);
  width: 20px;
  height: 20px;
}
.bottomaction-copy {
  flex: 1;
  width: auto;
  cursor: pointer;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 5px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-bottom: 5px;
  justify-content: center;
  background-color: white;
  border-top-left-radius: 0;
  border-top-right-radius: 4;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 4;
}
.bottomaction-copy:hover {
  background-color: #dce0e1;
}
.bottomaction-icon3 {
  fill: var(--dl-color-gray-500);
  width: 20px;
  height: 20px;
}

.message-option p {
  margin: 0;
  font-weight: 500;
  text-align: center;
}

.message-option-box {
  margin: 0 !important;
  width: 90% !important;
  padding: 8px !important;
}

.message-option {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: antiquewhite;
  min-height: 35px; /* Add a minimum height to prevent overlapping */
}
