
  .api-variablescomponent {
    flex: 0 0 auto;
    height: auto;
    display: flex;
    padding: 10px;
    align-items: flex-start;
    border-color: #DADBDD;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    background-color: white;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .api-navbar {
    flex: 0 0 auto;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-color: #DADBDD;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .api-text {
    color: rgb(131, 135, 140);
    cursor: pointer;
    font-size: 14px;
    margin-right: 15px;
    font-weight: 400;
  }

  .api_text_color {
    color: rgb(112, 92, 246);
  }

  .select_variable_container {
    flex: 0 0 auto;
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 40px;
    display: flex;
    align-items: flex-start;
  }

  .api-variablescomponent {
    border-color: #DADBDD;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-bottom: 20px;
  }
  

  .testt {
    flex: 0 0 auto;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-color: #DADBDD;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: space-between;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }



  .selectvariable-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .selectvariable-body {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    border-width: 1px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
  }
  .selectvariable-body1 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 30px;


  }
  .selectvariable-variabletypes {
    flex: 0 0 auto;
    width: 200px;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-right: 8px;
    flex-direction: column;
    border-color: #DADBDD;
    border-right-width: 1px;
  }
  .selectvariable-text {
    color: rgb(81, 87, 94);
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    transition: 0.3s;
    font-weight: 500;
    padding-top: 9px;
    padding-left: 10px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-bottom: 9px;
    background-color: #D9D9D9;
  }
  .selectvariable-text:hover {
    background-color: #e4e8e8;
  }
  .selectvariable-text1 {
    color: rgb(81, 87, 94);
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    transition: 0.3s;
    font-weight: 500;
    padding-top: 9px;
    padding-left: 10px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-bottom: 9px;
  }
  .selectvariable-text1:hover {
    background-color: #e4e8e8;
  }
  .selectvariable-text2 {
    color: rgb(81, 87, 94);
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    transition: 0.3s;
    font-weight: 500;
    padding-top: 9px;
    padding-left: 10px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-bottom: 9px;
  }
  .selectvariable-text2:hover {
    background-color: #e4e8e8;
  }
  .selectvariable-variablevalues {
    flex: 1;
    width: 300px;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 10px;
  }
  .selectvariable-text3 {
    color: rgb(81, 87, 94);
    width: 100%;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    text-align: left;
    transition: 0.3s;
    font-weight: 400;
    padding-top: 9px;
    padding-left: 10px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-bottom: 9px;
    background-color: #e4e8e8;
  }
  .selectvariable-text3:hover {
    background-color: #e4e8e8;
  }
  .selectvariable-text4 {
    color: rgb(81, 87, 94);
    width: 100%;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    text-align: left;
    transition: 0.3s;
    font-weight: 400;
    padding-top: 9px;
    padding-left: 10px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-bottom: 9px;
  }
  .selectvariable-text4:hover {
    background-color: #e4e8e8;
  }
  .selectvariable-text5 {
    color: rgb(81, 87, 94);
    width: 100%;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    text-align: left;
    transition: 0.3s;
    font-weight: 400;
    padding-top: 9px;
    padding-left: 10px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-bottom: 9px;
  }
  .selectvariable-text5:hover {
    background-color: #e4e8e8;
  }
  