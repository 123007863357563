.header-header {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: 30px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 30px;
  justify-content: space-between;
}
.header-logo {
  color: #711755;
  font-size: 24px;
}
.header-navbar-container {
  width: 350px;
  display: flex;
  position: relative;
}
.header-navbar {
  width: 464px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-navlink {
  color: rgb(81, 87, 94);
  cursor: pointer;
  text-decoration: none;
}
.header-navlink1 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  text-decoration: none;
}
.header-navlink2 {
  color: rgb(81, 87, 94);
  cursor: pointer;
  text-decoration: none;
}
.header-navlink3 {
  color: #51575E;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}
.header-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-sign-in {
  color: #51575E;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 0px;
  border-width: 0px;
  margin-right: 0px;
  background-color: transparent;
}
.header-register {
  color: #51575E;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-color: #51575E;
  border-radius: 5px;
  background-color: transparent;
}
.header-burger-menu {
  display: none;
}
.header-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.header-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-image {
  height: 2rem;
}
.header-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

@media(max-width: 767px) {
  .header-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-navbar-container {
    display: none;
  }
  .header-navbar {
    align-items: flex-start;
    flex-direction: column;
  }
  .header-navlink {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-btn-group {
    display: none;
  }
  .header-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .header-header {
    padding: var(--dl-space-space-unit);
  }
  .header-mobile-menu {
    padding: 16px;
  }
}
